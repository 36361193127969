<template>
  <v-container>
    <v-card class="mb-4">
      <v-layout>
        <v-flex xs6 sm6 md3 class="mt-4 mb-4 ml-4 mr-4">
                    <v-select
                        v-model="buildingValue"
                        :items="building_items"
                        label="Building"
                        outlined
                        >
                    </v-select>
                </v-flex>
                <v-flex xs6 sm6 md3 class="mt-4 mb-4 ml-4 mr-4">
                    <v-select
                        class="ml-2"
                        v-model="floorValue"
                        :items="floor_items"
                        label="Floor"
                        outlined
                        >
                    </v-select>
                </v-flex>
                <v-flex xs6 sm6 md3 class="mt-4 mb-4 ml-4 mr-4">
                    <v-select
                        v-model="sensorValue"
                        :items="sensor_items"
                        label="Sensor"
                        outlined
                        >
                    </v-select>
        </v-flex>
      </v-layout>
            <v-card-title>Quick filters</v-card-title>
            <v-card-text>
                <v-layout justify-start class="mt-4">
                    <v-flex xs12 sm12 md2 class="mr-4" >
                        <v-card @click="filter('sm')"  height="100%">
                            <v-card-text>
                                <v-list-item>
                                    <v-list-item-avatar><v-img src="../assets/6468588_roll_towel_paper_tissue_dispenser.png" /></v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title style="font-weight: bold;">3</v-list-item-title>
                                        <v-list-item-subtitle class="text-wrap">Smart dispenser</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-card-text>
                        </v-card>
                    </v-flex>
                    <v-flex xs12 sm12 md2>
                        <v-card   @click="filter('aq')" height="100%">
                            <v-card-text>
                                <v-list-item>
                                    <v-list-item-avatar><v-img src="../assets/folded_towels_white.png" /></v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title style="font-weight: bold;">2</v-list-item-title>
                                        <v-list-item-subtitle class="text-wrap">Air quality</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-card-text>
                        </v-card>
                    </v-flex>
                    <v-flex xs12 sm12 md2>
                        <v-card   @click="filter('door')" height="100%">
                            <v-card-text>
                                <v-list-item>
                                    <v-list-item-avatar><v-icon large color="black">mdi-motion-sensor</v-icon></v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title style="font-weight: bold;">4</v-list-item-title>
                                        <v-list-item-subtitle class="text-wrap">Doors</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-card-text>
                        </v-card>
                    </v-flex>
                    <v-flex xs12 sm12 md2>
                        <v-card  @click="filter('bin')" height="100%">
                            <v-card-text>
                                <v-list-item>
                                    <v-list-item-avatar><v-img src="../assets/folded_towels_white.png" /></v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title style="font-weight: bold;">4</v-list-item-title>
                                        <v-list-item-subtitle class="text-wrap">Bins</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-card-text>
                        </v-card>
                    </v-flex>
                     <v-flex xs3 sm3 md2>
                        
                    </v-flex>
                    <v-flex xs12 sm12 md2 >
                      <v-btn @click="filter('')">Reset filter</v-btn>
                    </v-flex>
                </v-layout>
            </v-card-text>
         </v-card>
    <v-data-table
          v-bind:headers="headers"
          :items="items_ok"
          :hide-default-footer="true"
          disable-pagination
          class="elevation-1"
        >
           <template v-slot:item="{ item }">
           <tr @click="Grafici(item.redirect)">
            <td></td>
                <td v-if="item.name == 'Dispenser sapone'" ><img style="max-width: 60px;" src="../assets/dispenser_sapone_black_2.png" /></td>
                <td v-if="item.name == 'Toilet Paper Roll'" ><img style="max-width: 60px;" src="../assets//6468588_roll_towel_paper_tissue_dispenser.png" /></td>
                <td v-if="item.name == 'Hand Paper Roll'" ><img style="max-width: 60px;" src="../assets/6468584_paper_hand_towel_tissue_dispenser.png" /></td>
                <td v-if="item.icon != ''"><v-icon large color="black" v-html="item.icon"></v-icon></td>
                <td>{{ item.type }}</td>
                <td>{{ item.name }}</td>
                <td>{{ item.building }}</td>
                <td>{{ item.piano }}</td>
                <td><v-icon :style="item.stato == 'red' ? 'color: red;' : 'color: green;'">mdi-checkbox-blank-circle</v-icon></td>
           </tr>
           </template>
        </v-data-table>
  </v-container>
</template>

<script>

export default {
  name: 'SensorView',
  data(){
    return{
      pagination: { 'sortBy': 'name', 'descending': true, rowsPerPage: 25 },
      selected: [],
      items_ok: [],
      items: [{ type: "sm", name: "Toilet Paper Roll", piano: "terzo piano", building: "YouTissue", stato: "green", signal: "ok", icon: "", redirect: "smartdispenser"},
              { type: "sm", name: "Dispenser sapone",  piano: "primo piano", building: "YouTissue", stato: "red", signal: "ok", icon: "", redirect: "smartdispenser"},
              { type: "sm", name: "Hand Paper Roll", piano: "terzo piano", building: "YouTissue", stato: "green", signal: "ko", icon: "", redirect: "smartdispenser"},
              { type: "aq", name: "View Plus Air Quality", piano: "terzo piano", building: "YouTissue", stato: "green", signal: "ok", icon: "mdi-weather-windy", redirect: "airquality"},
              { type: "aq", name: "Wave Plus Air Quality", piano: "terzo piano", building: "YouTissue", stato: "red", signal: "ok", icon: "mdi-weather-windy", redirect: "airquality"},
              { type: "bin", name: "Cestino bagno proprietà", piano: "terzo piano", building: "YouTissue", stato: "green", signal: "ok", icon: "mdi-delete-variant", redirect: "smartbin"},
              { type: "bin", name: "Cestino bagno dipendenti",  piano: "primo piano", building: "YouTissue", stato: "red", signal: "ok", icon: "mdi-delete-variant", redirect: "smartbin"},
              { type: "bin", name: "Cestino bagno ospiti", piano: "secondo piano", building: "YouTissue", stato: "green", signal: "ko", icon: "mdi-delete-variant", redirect: "smartbin"},
              { type: "bin", name: "Cestino bagno dipendenti", piano: "terzo piano", building: "YouTissue", stato: "red", signal: "ko", icon: "mdi-delete-variant", redirect: "smartbin"},
              { type: "door", name: "Porta bagno proprietà", piano: "terzo piano", building: "YouTissue", stato: "green", signal: "ok", icon: "mdi-motion-sensor", redirect: "smartcleaning"},
              { type: "door", name: "Porta bagno dipendenti",  piano: "primo piano", building: "YouTissue", stato: "red", signal: "ok", icon: "mdi-motion-sensor", redirect: "smartcleaning"},
              { type: "door", name: "Porta bagno dipendenti", piano: "terzo piano", building: "YouTissue", stato: "green", signal: "ko", icon: "mdi-motion-sensor", redirect: "smartcleaning"},
              { type: "door", name: "Porta bagno ospiti", piano: "terzo piano", building: "YouTissue", stato: "red", signal: "ok", icon: "mdi-motion-sensor", redirect: "smartcleaning"}
              ],
      headers: [{text: ""},
        { text: "", align: "center"},
        { text: "Type", align: "center", value: "type"},
        { text: "Dispenser name", align: "center", value: "name" },
        { text: "Building", align: "center", value: "building" },
        { text: "Floor", align: "center", value: "piano" },
        { text: "Online/Offline", align: "center", value: "stato" } ,
        {text: ""}
      ]
    }
  },
  methods: {
    Grafici(redirect){
      this.$router.push({ path: `/` + redirect });
    },
    filter(value){
      value == ''? this.items_ok = this.items :  
      this.items_ok = this.items.filter(item => item.type == value)
    }
  },
  mounted(){
    
    this.items_ok = this.items;

  }
}
</script>
