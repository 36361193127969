<template>
    <v-container>
        <v-overlay v-model="overlay" color="white" opacity="1" persistent>
            <v-container style="text-align: center">
                <v-progress-circular :size="50" color="#234a98" indeterminate></v-progress-circular>
                <p class="pt-2" style="font-size: 14px; color: #234a98">
                    Loading...
                </p>
            </v-container>
        </v-overlay>
        <v-card class="mt-4 mb-4">
            <v-layout id="makeMeBlue">
                <v-flex xs6 sm6 md3 class="mt-4 mb-4 ml-4 mr-4">
                    <v-select v-model="client" color="#213A8F" outlined :items="clients" label="Client"
                        prepend-inner-icon="mdi-account" append-icon="mdi-arrow-down-drop-circle-outline"
                        append-icon-color="#213A8F" />
                </v-flex>
                <v-flex xs6 sm6 md3 class="mt-4 mb-4 ml-4 mr-4">
                    <v-select prepend-inner-icon="mdi-home" v-model="buildingValue" :items="buildings" label="Building"
                        outlined append-icon="mdi-arrow-down-drop-circle-outline">
                    </v-select>
                </v-flex>
                <v-flex xs6 sm6 md3 class="mt-4 mb-4 ml-4 mr-4">
                    <v-select prepend-inner-icon="mdi-floor-plan" class="ml-2"
                        append-icon="mdi-arrow-down-drop-circle-outline" v-model="floorValue" :items="floors"
                        label="Floor" outlined :key="floorKey">
                    </v-select>
                </v-flex>
                <v-flex xs6 sm6 md3 class="mt-4 mb-4 ml-4 mr-4">
                    <v-select v-model="areaValue" color="#213A8F" outlined :items="areas" label="Area"
                        prepend-inner-icon="mdi-texture-box" append-icon="mdi-arrow-down-drop-circle-outline"
                        append-icon-color="#213A8F" />
                </v-flex>
                <v-flex xs6 sm6 md3 class="mt-4 mb-4 ml-4 mr-4">
                    <v-select v-model="sensorValue" prepend-inner-icon="mdi-access-point-network"
                        append-icon="mdi-arrow-down-drop-circle-outline" :items="sensors" label="Sensor" outlined
                        :key="sensorKey">
                    </v-select>
                </v-flex>
            </v-layout>
            <v-card-actions>
                <v-btn text @click="ClearFilter">
                    <span style="color: #213A8F;">Clear filter</span>
                    <v-icon color="#213A8F" left>mdi-close</v-icon>
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn @click="FilterAlert">
                    <span style="color: #213A8F;">Filter</span>
                    <v-icon color="#213A8F" left>mdi-magnify</v-icon>
                </v-btn>
            </v-card-actions>
        </v-card>

        <v-card>

            <v-layout id="makeMeBlue">
                <v-flex xs12 sm12 md12>
                    <v-card-text>
                        <v-data-table v-bind:headers="headers" :items="items" :hide-default-footer="true"
                            disable-pagination class="elevation-1 mt-4" style="overflow-x: auto;">
                            <template v-slot:item="{ item }">
                                <tr>
                                    <td v-if="item.Gestito == 0"><v-icon color="red">mdi-close-circle-outline</v-icon>
                                    </td>
                                    <td v-if="item.Gestito == 1"><v-icon color="green">mdi-check-circle-outline</v-icon>
                                    </td>
                                    <td>
                                        <img class="mt-2 mr-4 align-center" style="width: 50px; align:center"
                                            :src="item.DispenserImage" />
                                    </td>
                                    <td>{{ item.DeviceName }}</td>
                                    <td>{{ $store.state.company == '1' ? item.BuildingName.replace('Zwetko Headquarter',
            'Airport') : item.BuildingName }}</td>
                                    <td>{{ item.FloorName }}</td>
                                    <td>{{ item.Message }}</td>
                                    <td>{{ item.Timestamp }}</td>
                                    <td>{{ item.Timestamp_Gestione }}</td>
                                    <td><v-icon color="#213A8F"
                                            @click="MarkAsDone(item.idalerts, item.Gestito == 0 ? 1 : 0, item.DeviceID)">mdi-pencil-circle-outline</v-icon>
                                    </td>
                                </tr>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-flex>
            </v-layout>
        </v-card>

    </v-container>
</template>

<script>
import axios from "axios";
export default {
    name: 'AlertsView',
    data() {
        return {
            areas: [],
            areaValue: '',
            clients: [],
            client: '',
            buildings: [],
            floors: [],
            sensors: [],
            isFilterActive: false,
            selectedSensor: '',
            sensorKey: 0,
            floorKey: 0,
            buildingKey: 0,
            overlay: false,
            building_name: "YouTissue HQ",
            floor_name: "First",
            daGestire: 5,
            pagination: { 'sortBy': 'name', 'descending': true, rowsPerPage: 25 },
            selected: [],
            items: [],
            sensor_items: [],
            sensorValue: '',
            building_items: [],
            buildingValue: '',
            floor_items: [],
            items_ok: [],
            floorValue: '',
            headers_it: [
                { text: "", align: "left", value: "Gestito" },
                { text: "Device ID", align: "left", value: "deviceID" },
                { text: "Device", align: "left", value: "deviceName" },
                // { text: "Posizione", align: "left", value: "position" },
                { text: "Distanza", align: "left", value: "distance" },
                { text: "Data e ora", align: "left", value: "timestamp" },
            ],
            headers: [
                { text: "", align: "center", value: "Gestito", sortable: "false" },
                { text: "TYPE", align: "center", value: "Type" },
                { text: "SENSOR", align: "center", value: "DeviceName" },
                { text: "BUILDING", align: "center", value: "Building" },
                { text: "FLOOR", align: "center", value: "Floor" },
                { text: "MESSAGE", align: "center", value: "Message" },
                { text: "ALERT TIME", align: "center", value: "Timestamp" },
                { text: "SOLVED TIME", align: "center", value: "Timestamp_Gestione" },
                { text: "", align: "center", value: "", sortable: "false" },
            ]
        }
    },
    created() {
        if (this.$store.state.company == null || this.$store.state.company == undefined || this.$store.state.company == '') {
            let token = localStorage.getItem("token");
            if (token != null && token != undefined && token != '') {
                axios.defaults.headers.common["Authorization"] = token;
                axios.post('https://2ahfbwerq7qttajotsaeim3g6i0dvrte.lambda-url.eu-central-1.on.aws/', {

                })
                    .then(res => {
                        if (res.data.result == "ok") {
                            this.$store.state.company = res.data.company;
                            localStorage.setItem('token', res.data.token);
                            localStorage.setItem('company', res.data.company)
                            this.$store.state.menu = res.data.menu;
                            console.log(this.$store.state.menu)
                            this.$store.state.avatar = res.data.avatar;
                            this.$store.state.displayName = res.data.displayName;
                            axios.defaults.headers.common["Authorization"] = res.data.token;
                            axios.post(this.$store.state.URL_COMPANY_DATA, {
                                "Company": this.$store.state.company
                            })
                                .then(() => {
                                    this.FilterAlert();
                                    axios.post(this.$store.state.URL_GET_AVAILABLE_CLIENT)
                                        .then(res => {
                                            res.data.forEach(el => {
                                                var element = { text: el.CompanyName, value: el.id }
                                                this.clients.push(element);

                                            })
                                        })
                                        .catch(() => {
                                            this.overlay = false;
                                            this.$router.push({ path: "/" });
                                        })
                                })
                                .catch(() => {
                                    this.overlay = false;
                                    this.$router.push({ path: "/" });
                                })
                        }
                    })
            }


        } else {
            this.FilterAlert();
            axios.post(this.$store.state.URL_GET_AVAILABLE_CLIENT)
                .then(res => {
                    res.data.forEach(el => {
                        var element = { text: el.CompanyName, value: el.id }
                        this.clients.push(element);

                    })
                })
                .catch(() => {
                    this.overlay = false;
                    this.$router.push({ path: "/" });
                })
            setTimeout(() => this.FilterAlert(), 300000);
        }
    },
    // mounted(){
    //     this.GetData();
    //     setTimeout(() => this.GetData(), 300000);
    // },
    methods: {
        GetData() {
            this.overlay = true;
            axios.post(this.$store.state.URL_ALERTS, {
                "companyId": this.$store.state.company,
            })
                .then(res => {

                    this.items.length = 0;
                    this.items_ok.length = 0;
                    this.items = res.data;
                    this.items_ok = res.data;
                    axios.post(this.$store.state.URL_GET_AVAILABLE_CLIENT)
                        .then(res => {
                            res.data.forEach(el => {
                                var element = { text: el.CompanyName, value: el.id }
                                this.clients.push(element);

                            })
                        })
                        .catch(() => {
                            this.overlay = false;
                            this.$router.push({ path: "/" });
                        })
                    // console.log(this.items)

                    // let buildings = [];
                    // let floors = [];
                    // this.sensor_items.push({ text: "ALL", value: "ALL" });
                    // this.items.map(el => {
                    //     if (!buildings.includes(el.BuildingName)) {

                    //         if (this.$store.state.company == '1') {
                    //             buildings.push(el.BuildingName.replace('Zwetko Headquarter', 'Airport'));
                    //             this.building_items.push({ text: el.BuildingName.replace('Zwetko Headquarter', 'Airport'), value: el.BuildingName.replace('Zwetko Headquarter', 'Airport') });
                    //         }

                    //         else {
                    //             buildings.push(el.BuildingName);
                    //             this.building_items.push({ text: el.BuildingName, value: el.BuildingName });
                    //         }


                    //     }
                    //     if (this.building_items.length == 1)
                    //         this.buildingValue = this.building_items[0].value;
                    //     if (!floors.includes(el.FloorName)) {
                    //         floors.push(el.FloorName);
                    //         this.floor_items.push({ text: el.FloorName, value: el.FloorName })
                    //     }
                    //     if (this.building_items.length == 1)
                    //         this.floorValue = this.floor_items[0].value;
                    //     if (!this.sensor_items.includes(el.DeviceName)) {
                    //         this.sensor_items.push(el.DeviceName);
                    //         this.sensor_items.push({ text: el.DeviceName, value: el.DeviceName })
                    //     }

                    // })
                    // if (this.$store.state.isFilterActive) {
                    //     this.buildingValue = this.$store.state.buildingFiltered;
                    //     this.floorValue = this.$store.state.floorFiltered;
                    //     this.sensorValue = this.$store.state.sensorFiltered;
                    //     this.buildingKey++;
                    //     this.floorKey++;
                    //     this.sensorKey++;
                    // }
                    this.overlay = false;
                })
        },
        MarkAsDone(id_, stato, sensor_id) {
            var now = new Date().toISOString().toString().substr(0, 10) + "T" + new Date().toLocaleTimeString();
            axios.post(this.$store.state.URL_UPDATE_ALERTS, {
                "id": id_,
                "stato": stato,
                "sensore": sensor_id,
                "time": now
            })
                .then(res => {
                    console.log(res.data);
                    this.FilterAlert();
                })

        },
        ClearFilter() {
            this.client = '';
            this.clients.length = 0;
            this.buildings.length = 0;
            this.buildingValue = '';
            this.floorValue = '';
            this.floors.length = 0;
            this.areaValue = '';
            this.areas.length = 0;
            this.sensors.length = 0;
            this.sensorValue = '';
            this.buildings = [];
            this.floors = [];
            this.areas = [];
            this.sensors = [];
            this.FilterAlert();
        },
        FilterAlert() {
            this.overlay = true;
            axios.post(this.$store.state.URL_ALERTS, {
                "companyId": this.$store.state.company,
                "clientId": this.client,
                "buildingId": this.buildingValue,
                "floorId": this.floorValue,
                "areaId": this.areaValue,
                "sensorId": this.sensorValue
            })
                .then(res => {

                    this.items.length = 0;
                    this.items_ok.length = 0;
                    this.items = res.data;
                    this.items_ok = res.data;
                    this.overlay = false;
                })
        }
    },
    watch: {
        client(value) {
            if (value != undefined &&
                value != null &&
                value != "" &&
                value != 0
            ) {
                this.areas.length = 0;
                this.areaValue = '';
                this.areas = [];
                this.floors.length = 0;
                this.floorValue = '';
                this.floors = [];
                this.buildings.length = 0;
                this.buildingValue = '';
                this.buildings = [];
                this.sensors.length = 0;
                this.sensorValue = '';
                this.sensors = [];
                var el = { text: "...", value: "" };
                this.buildings.push(el);
                axios.post(this.$store.state.URL_GET_AVAILABLE_BUILDINGS, {
                    companyId: value
                })
                    .then(res => {
                        res.data.forEach(el => {
                            var element = { text: el.BuildingName, value: el.id }
                            this.buildings.push(element);

                        });
                    })
            }
            else {
                this.areas.length = 0;
                this.areaValue = '';
                this.areas = [];
                this.floors.length = 0;
                this.floorValue = '';
                this.floors = [];
                this.buildings.length = 0;
                this.buildingValue = '';
                this.buildings = [];
                this.sensors.length = 0;
                this.sensorValue = '';
                this.sensors = [];
            }
        },
        buildingValue(value) {
            if (value != undefined &&
                value != null &&
                value != "" &&
                value != 0
            ) {
                this.areas.length = 0;
                this.areaValue = '';
                this.areas = [];
                this.floors.length = 0;
                this.floorValue = '';
                this.floors = [];
                this.sensors.length = 0;
                this.sensorValue = '';
                this.sensors = [];
                var el = {text: "..." , value: ""};
                this.floors.push(el);
                axios.post(this.$store.state.URL_GET_AVAILABLE_FLOORS, {
                    buildingId: value
                })
                    .then(res => {
                        res.data.forEach(el => {
                            var element = { text: el.FloorName, value: el.id }
                            this.floors.push(element);
                        });

                    })
            } else {
                this.areas.length = 0;
                this.areaValue = '';
                this.areas = [];
                this.floors.length = 0;
                this.floorValue = '';
                this.floors = [];
                this.sensors.length = 0;
                this.sensorValue = '';
                this.sensors = [];
            }
        },
        floorValue(value) {
            if (value != undefined &&
                value != null &&
                value != "" &&
                value != 0
            ) {
                this.areas.length = 0;
                this.areaValue = '';
                this.areas = [];
                this.sensors.length = 0;
                this.sensorValue = '';
                this.sensors = [];
                var el = {text: "..." , value: ""};
                this.areas.push(el);
                axios.post(this.$store.state.URL_GET_AVAILABLE_AREAS, {
                    floorId: value
                })
                    .then(res => {
                        res.data.forEach(el => {
                            var element = { text: el.AreaName, value: el.id }
                            this.areas.push(element);
                        });

                    })
            }
            else{
                this.areas.length = 0;
                this.areaValue = '';
                this.areas = [];
                this.sensors.length = 0;
                this.sensorValue = '';
                this.sensors = [];
            }

        },
        areaValue(value) {
            if (value != undefined &&
                value != null &&
                value != "" &&
                value != 0
            ) {
                this.sensors.length = 0;
                this.sensorValue = '';
                this.sensors = [];
                axios.post(this.$store.state.URL_GET_SENSORS_COMPANY_BUILDING_FLOOR_AREA, {
                    companyId: this.client,
                    buildingId: this.buildingValue,
                    floorId: this.floorValue,
                    areaId: value
                })
                    .then(res => {
                        res.data.forEach(el => {
                            var element = { text: el.DispenserUnique, value: el.id }
                            this.sensors.push(element);
                        });

                    })
            }
            else{
                this.sensors.length = 0;
                this.sensorValue = '';
                this.sensors = [];
            }
        },
        // sensorValue(val) {
        //     this.$store.state.isFilterActive = true;
        //     this.$store.state.sensorFiltered = val;
        //     if (val != "ALL") {
        //         this.items = this.items_ok;
        //         this.items = this.items.filter(p => p.DeviceName == val);
        //     } else {
        //         this.items = this.items_ok;
        //     }
        // },

    },
}
</script>
<style>
.theme--light.v-data-table>.v-data-table__wrapper>table>thead>tr>th:not(:last-child) {
    color: #213A8F;
    font-size: 14px;
    border-right: 1px solid #bfcce4;
    line-height: 3px;
    border-bottom: 1px solid #bfcce4;
    border-top: 1px solid #bfcce4;
    text-align: center !important;
    white-space: nowrap;
    font-weight: bolder;
}

.theme--light.v-data-table>.v-data-table__wrapper>table>thead>tr>th:first-child {
    border: none !important;
}

.theme--light.v-data-table>.v-data-table__wrapper>table>thead>tr>th:nth-last-child(2) {
    border-right: none !important;
}

/* .theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr > th{
        border-bottom: none !important;
    } */

.theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr>td,
.theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr>th:not(.v-data-table__mobile-row) {
    border: none !important;
}
</style>