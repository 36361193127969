<template>
    <v-container @keydown="CheckInvio()">
        <v-overlay v-model="overlay" color="white" opacity="1" persistent>
            <v-container style="text-align: center">
                <v-progress-circular :size="50" color="#234a98" indeterminate></v-progress-circular>
                <p class="pt-2" style="font-size: 14px; color: #234a98">
                    Loading...
                </p>
            </v-container>
        </v-overlay>
        <v-dialog persistent max-width="600" v-model="showDeleteAlert" :z-index="5000">
            <v-card>
                <v-card-title>Sensor Deleting</v-card-title>
                <v-card-text>
                    <h4>Are you sure you want to delete "{{ nameDeleting }}"?</h4>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text color="#213A8F" @click="showDeleteAlert = false">Cancel</v-btn>
                    <v-btn text color="#213A8F" @click="ConfirmDelete">Confirm</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog persistent max-width="600" v-model="dialogAddBuilding" :z-index="5000">
            <v-card>
                <v-card-title>
                    CREATE A NEW BUILDING : Please fill the field below
                </v-card-title>
                <v-card-text id="makeMeBlue">
                    <v-text-field label="Building name" v-model="newBuildingName" outlined color="#213A8F"
                        maxlength="50" counter />
                    <v-text-field label="Building address" v-model="newBuildingAddress" outlined color="#213A8F"
                        maxlength="50" counter />
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text color="#213A8F" @click="dialogAddBuilding = false">Cancel</v-btn>
                    <v-btn text color="#213A8F" @click="CreateBuilding()">Save</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog persistent max-width="600" v-model="dialogAddFloor" :z-index="5000">
            <v-card>
                <v-card-title>
                    CREATE A NEW Floor : Please fill the fields below
                </v-card-title>
                <v-card-text id="makeMeBlue">
                    <v-select v-model="buildingValue" color="#213A8F" outlined :items="buildings" label="Building"
                        prepend-inner-icon="mdi-home" append-icon="mdi-arrow-down-drop-circle-outline"
                        append-icon-color="#213A8F" v-if="showBuilding" :disabled="true" />
                    <v-text-field label="Floor name" v-model="newFloorName" outlined color="#213A8F" maxlength="50"
                        counter />
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text color="#213A8F" @click="CreateFloor()">Save</v-btn>
                    <v-btn text color="#213A8F" @click="dialogAddFloor = false">Cancel</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog persistent max-width="600" v-model="dialogAddArea" :z-index="5000">
            <v-card>
                <v-card-title>
                    CREATE A NEW Area : Please fill the fields below
                </v-card-title>
                <v-card-text id="makeMeBlue">
                    <v-select v-model="buildingValue" color="#213A8F" outlined :items="buildings" label="Building"
                        prepend-inner-icon="mdi-home" append-icon="mdi-arrow-down-drop-circle-outline"
                        append-icon-color="#213A8F" v-if="showBuilding" :disabled="true" />
                    <v-select v-model="floorValue" color="#213A8F" outlined :items="floors" label="Floor"
                        prepend-inner-icon="mdi-floor-plan" append-icon="mdi-arrow-down-drop-circle-outline"
                        append-icon-color="#213A8F" :key="f" v-if="showFloor" />
                    <v-text-field label="Area name" v-model="newAreaName" outlined color="#213A8F" maxlength="50"
                        counter />
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text color="#213A8F" @click="CreateArea()">Save</v-btn>
                    <v-btn text color="#213A8F" @click="dialogAddArea = false">Cancel</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog persistent max-width="600" v-model="dialogAdd" :z-index="1000">
            <v-card>
                <v-card-title>
                    SENSOR QUICK SETUP : Please fill the fields below
                </v-card-title>
                <v-card-text id="makeMeBlue">
                    <v-text-field label="Sensor ID" v-model="devEui" outlined color="#213A8F" maxlength="50" counter />
                    <v-text-field label="Dispenser Name" v-model="dispenserName" outlined color="#213A8F" maxlength="20"
                        counter />
                    <v-select v-model="dispenserBrand" color="#213A8F" outlined :items="dispenserBrands"
                        label="Dispenser Brand" prepend-inner-icon="mdi-access-point-network"
                        append-icon="mdi-arrow-down-drop-circle-outline" append-icon-color="#213A8F" />
                    <v-select v-model="dispenserArticleCode" color="#213A8F" outlined :items="dispenserArticleCodes"
                        label="Dispenser Article Code" prepend-inner-icon="mdi-access-point-network"
                        append-icon="mdi-arrow-down-drop-circle-outline" append-icon-color="#213A8F"
                        v-if="showDispenserArticleCode" />
                    <v-select v-model="consumableBrand" color="#213A8F" outlined :items="consumableBrands"
                        label="Consumable Brand" prepend-inner-icon="mdi-access-point-network"
                        append-icon="mdi-arrow-down-drop-circle-outline" append-icon-color="#213A8F"
                        v-if="showConsumableBrand" />
                    <v-select v-model="consumableArticleCode" color="#213A8F" outlined :items="consumableArticleCodes"
                        label="Consumable Article Code" prepend-inner-icon="mdi-access-point-network"
                        append-icon="mdi-arrow-down-drop-circle-outline" append-icon-color="#213A8F"
                        v-if="showConsumableArticleCode" />
                    <v-select v-model="client" color="#213A8F" outlined :items="clients" label="Client"
                        prepend-inner-icon="mdi-account" append-icon="mdi-arrow-down-drop-circle-outline"
                        append-icon-color="#213A8F" />
                    <v-layout>
                        <v-flex xs-11 md-11 sm-11>
                            <v-select v-model="buildingValue" color="#213A8F" outlined :items="buildings"
                                label="Building" prepend-inner-icon="mdi-home"
                                append-icon="mdi-arrow-down-drop-circle-outline" append-icon-color="#213A8F"
                                v-if="showBuilding" /></v-flex>
                        <v-flex xs-1 md-1 sm-1>
                            <v-icon v-if="showBuilding" class="mt-4 mb-4" color="#213A8F" text
                                @click="CreateNewBuilding()">mdi-plus-circle-outline</v-icon></v-flex>
                    </v-layout>
                    <v-layout>
                        <v-flex xs-11 md-11 sm-11>
                            <v-select v-model="floorValue" color="#213A8F" outlined :items="floors" label="Floor"
                                prepend-inner-icon="mdi-floor-plan" append-icon="mdi-arrow-down-drop-circle-outline"
                                append-icon-color="#213A8F" :key="f" v-if="showFloor" /></v-flex>
                        <v-flex xs-1 md-1 sm-1>
                            <v-icon v-if="showFloor" class="mt-4 mb-4" color="#213A8F" text
                                @click="CreateNewFloor()">mdi-plus-circle-outline</v-icon></v-flex>
                    </v-layout>

                    <v-layout>
                        <v-flex xs-11 md-11 sm-11>
                            <v-select v-model="areaValue" color="#213A8F" outlined :items="areas" label="Area"
                                prepend-inner-icon="mdi-texture-box" append-icon="mdi-arrow-down-drop-circle-outline"
                                append-icon-color="#213A8F" :key="a" v-if="showArea" /></v-flex>
                        <v-flex xs-1 md-1 sm-1>
                            <v-icon v-if="showArea" class="mt-4 mb-4" color="#213A8F" text
                                @click="CreateNewArea()">mdi-plus-circle-outline</v-icon></v-flex>
                    </v-layout>
                    <v-text-field v-if="sheetsVisible" outlined v-model="maxPaper"
                        label="Max N. of sheets"></v-text-field>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text color="#213A8F" @click="CreateSensor">Save</v-btn>
                    <v-btn text color="#213A8F" @click="dialogAdd = false">Cancel</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-card>
            <div v-if="addButton">
                <v-icon class="mt-4 mb-4" color="#213A8F" text @click="CreateNew()">mdi-plus-circle-outline</v-icon>Add
                new
                sensor
            </div>
            <v-card class="mt-4 mb-4">
                <v-layout id="makeMeBlue">
                    <v-flex xs6 sm6 md3 class="mt-4 mb-4 ml-4 mr-4">
                        <v-select v-model="filterClient" color="#213A8F" outlined :items="filterClients" label="Client"
                            prepend-inner-icon="mdi-account" append-icon="mdi-arrow-down-drop-circle-outline"
                            append-icon-color="#213A8F" />
                    </v-flex>
                    <v-flex xs6 sm6 md3 class="mt-4 mb-4 ml-4 mr-4">
                        <v-select prepend-inner-icon="mdi-home" v-model="filterBuilding" :items="filterBuildings"
                            label="Building" outlined append-icon="mdi-arrow-down-drop-circle-outline">
                        </v-select>
                    </v-flex>
                    <v-flex xs6 sm6 md3 class="mt-4 mb-4 ml-4 mr-4">
                        <v-select prepend-inner-icon="mdi-floor-plan" class="ml-2"
                            append-icon="mdi-arrow-down-drop-circle-outline" v-model="filterFloor" :items="filterFloors"
                            label="Floor" outlined>
                        </v-select>
                    </v-flex>
                    <v-flex xs6 sm6 md3 class="mt-4 mb-4 ml-4 mr-4">
                        <v-select v-model="filterArea" color="#213A8F" outlined :items="filterAreas" label="Area"
                            prepend-inner-icon="mdi-texture-box" append-icon="mdi-arrow-down-drop-circle-outline"
                            append-icon-color="#213A8F" />
                    </v-flex>
                    <v-flex xs6 sm6 md3 class="mt-4 mb-4 ml-4 mr-4">
                        <v-select v-model="filterSensor" prepend-inner-icon="mdi-access-point-network"
                            append-icon="mdi-arrow-down-drop-circle-outline" :items="filterSensors" label="Sensor"
                            outlined>
                        </v-select>
                    </v-flex>
                </v-layout>
                <v-card-actions>
                    <v-btn text @click="ClearFilter">
                        <span style="color: #213A8F;">Clear filter</span>
                        <v-icon color="#213A8F" left>mdi-close</v-icon>
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn @click="Filter">
                        <span style="color: #213A8F;">Filter</span>
                        <v-icon color="#213A8F" left>mdi-magnify</v-icon>
                    </v-btn>
                </v-card-actions>
            </v-card>
            <v-card>
                <!-- <v-card-actions class="mb-0">
                    <v-spacer></v-spacer>
                    <v-layout>
                        <v-flex xs3 sm3 md3>
                            <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line
                                outlined></v-text-field>
                        </v-flex>
                    </v-layout>
                </v-card-actions> -->
                <v-card-text class="mt-0">
                    <v-data-table v-bind:headers="headers" :items="items" :hide-default-footer="true" disable-pagination
                        class="elevation-1" :search="search">
                        <template v-slot:item="{ item }">
                            <tr>
                                <td>
                                    <v-icon v-if="item.status == 'on'"
                                        :style="{ color: item.stato }">mdi-checkbox-blank-circle</v-icon>
                                </td>
                                <td>
                                    <img class="mt-2 mr-4 align-center" style="width: 50px; align:center"
                                        :src="item.DispenserType" />
                                </td>
                                <td>{{ item.status == 'on' && item.percentuale > -1 ? (item.percentuale + '%') : '' }}
                                </td>
                                <td>{{ item.name }}</td>
                                <td>{{ item.Company }}</td>
                                <td>{{ item.BuildingName }}</td>
                                <td>{{ item.floor }}</td>
                                <td>{{ item.Area }}</td>
                                <td>{{ item.battery }}%</td>
                                <td>{{ item.sensorId }}</td>
                                <td><v-icon color="#213A8F" text @click="AlertDeleting(item.id, item.name)">mdi-delete</v-icon>
                                </td>
                            </tr>
                        </template>
                    </v-data-table>
                </v-card-text>
            </v-card>
        </v-card>
    </v-container>
</template>
<script>
import axios from "axios";
export default {
    data() {
        return {
            id_deleting: 0,
            dialogAddArea: false,
            newAreaName: '',
            newBuildingAddress: '',
            nameDeleting: '',
            showDeleteAlert: false,
            search: '',
            filterClient: '',
            filterClients: [],
            filterBuilding: '',
            filterBuildings: [],
            filterFloor: '',
            filterFloors: [],
            filterArea: '',
            filterAreas: [],
            filterSensor: '',
            filterSensors: [],
            newBuildingName: '',
            newFloorName: '',
            dialogAddFloor: false,
            dialogAddBuilding: false,
            consumableArticleCodes: [],
            showConsumableArticleCode: false,
            consumableBrands: [],
            showConsumableBrand: false,
            dispenserArticleCodes: [],
            showDispenserArticleCode: false,
            showArea: false,
            areas: [],
            showFloor: false,
            floors: [],
            showBuilding: false,
            buildings: [],
            clients: [],
            dispenserBrands: [],
            areaValue: '',
            devEui: '',
            dispenserName: '',
            dispenserBrand: '',
            dispenserArticleCode: '',
            consumableBrand: '',
            consumableArticleCode: '',
            client: '',
            filterBuildingValue: "ALL",
            filterBuilding_items: [],
            building_items: [],
            addButton: true,
            f: 0,
            b: 0,
            floor_items: [],
            floorValue: "",
            buildingValue: [],
            sensorTypes: [],
            Tipologiasensore: '',
            deveui: '',
            sheetsVisible: false,
            maxPaper: 0,
            overlay: true,
            dialogAdd: false,
            headers: [{},
            { text: "Type", align: "center", value: "DispenserType" },
            { text: "Fill level", align: "center", value: "percentuale" },
            { text: "Name", align: "center", value: "name" },
            { text: "Company", align: "center", value: "Company" },
            { text: "Building", align: "center", value: "BuildingName" },
            { text: "Floor", align: "center", value: "floor" },
            { text: "Area", align: "center", value: "Area" },
            { text: "Battery", align: "center", value: "Battery" },
            { text: "Sensor ID", align: "center", value: "sensorId" },
            {},
            ],
            items: [],
            items_all: []
        }
    },
    created() {
        if (this.$store.state.company == null || this.$store.state.company == undefined || this.$store.state.company == '') {
            let token = localStorage.getItem("token");
            if (token != null && token != undefined && token != '') {
                axios.defaults.headers.common["Authorization"] = token;
                axios.post('https://2ahfbwerq7qttajotsaeim3g6i0dvrte.lambda-url.eu-central-1.on.aws/',
                    {

                    })
                    .then(res => {
                        if (res.data.result == "ok") {
                            this.$store.state.company = res.data.company;
                            localStorage.setItem('token', res.data.token);
                            localStorage.setItem('company', res.data.company)
                            this.$store.state.menu = res.data.menu;
                            console.log(this.$store.state.menu)
                            this.$store.state.avatar = res.data.avatar;
                            this.$store.state.displayName = res.data.displayName;
                            axios.defaults.headers.common["Authorization"] = res.data.token;
                            this.items = [];
                            axios
                                .post(`${this.$store.state.URL_SENSORSMANAGEMENT}`,
                                    { id_company: this.$store.state.company, 'type': this.$route.params.type })
                                .then(res => {
                                    this.items = res.data.map(el => ({
                                        stato: el.DispenserType == 'Dispenser' ? el.percentuale == -1 ? "gray" : el.percentuale < 15 ? "red" : (el.percentuale < 40 ? "orange" : "green") : '',
                                        DispenserType: el.DispenserImage,
                                        percentuale: el.DispenserType == 'Dispenser' ? el.percentuale : '',
                                        name: el.DisplayName,
                                        Company: el.CompanyName,
                                        BuildingName: el.BuildingName,
                                        floor: el.FloorName,
                                        status: el.DispenserType == 'Dispenser' ? 'on' : 'off',
                                        battery: el.battery,
                                        Area: el.AreaName,
                                        sensorId: el.DispenserUnique,
                                        id: el.id
                                    }));
                                    this.items_all = this.items;
                                    this.filterBuilding_items = this.items.map(item => ({
                                        text: item.BuildingName,
                                        value: item.BuildingName
                                    }));
                                    this.filterBuilding_items.push({ text: "ALL", value: "ALL" });

                                    this.DownloadCompanyDatas();
                                    this.GetDispenserTypes();
                                    this.overlay = false;
                                })
                                .catch(error => {
                                    console.error('Error fetching sensor data:', error);
                                    this.overlay = false; // Ensure overlay is removed even if there's an error
                                });
                        }
                    })
            }
        }
        else {
            this.items = [];
            axios
                .post(`${this.$store.state.URL_SENSORSMANAGEMENT}`,
                    { id_company: this.$store.state.company, 'type': this.$route.params.type })
                .then(res => {
                    this.items = res.data.map(el => ({
                        stato: el.DispenserType == 'Dispenser' ? el.percentuale == -1 ? "gray" : el.percentuale < 15 ? "red" : (el.percentuale < 40 ? "orange" : "green") : '',
                        DispenserType: el.DispenserImage,
                        percentuale: el.DispenserType == 'Dispenser' ? el.percentuale : '',
                        name: el.DisplayName,
                        Company: el.CompanyName,
                        BuildingName: el.BuildingName,
                        floor: el.FloorName,
                        status: el.DispenserType == 'Dispenser' ? 'on' : 'off',
                        battery: el.battery,
                        Area: el.AreaName,
                        sensorId: el.DispenserUnique,
                        id: el.id
                    }));
                    this.items_all = this.items;
                    this.filterBuilding_items = this.items.map(item => ({
                        text: item.BuildingName,
                        value: item.BuildingName
                    }));
                    this.filterBuilding_items.push({ text: "ALL", value: "ALL" });
                    this.DownloadCompanyDatas();
                    this.GetDispenserTypes();
                    this.overlay = false;
                })
                .catch(error => {
                    console.error('Error fetching sensor data:', error);
                    this.overlay = false; // Ensure overlay is removed even if there's an error
                });
        }
    },
    methods: {
        CreateBuilding() {
            axios
                .post(`${this.$store.state.URL_CREATE_BUILDING}`,
                    { buildingName: this.newBuildingName, 'address': this.newBuildingAddress, 'companyId': this.client })
                .then(() => {
                    axios.post(this.$store.state.URL_GET_AVAILABLE_BUILDINGS, {
                    companyId: this.client
                })
                    .then(res => {
                        res.data.forEach(el => {
                            var element = { text: el.BuildingName, value: el.id }
                            this.buildings.push(element);

                        });
                        this.dialogAddBuilding = false;
                    })
                    
                })
        },
        CreateFloor() {
            axios
                .post(`${this.$store.state.URL_CREATE_FLOOR}`,
                    { floorName: this.newFloorName, buildingId: this.buildingValue })
                .then(() => {
                    axios.post(this.$store.state.URL_GET_AVAILABLE_FLOORS, {
                    buildingId: this.buildingValue
                })
                    .then(res => {
                        res.data.forEach(el => {
                            var element = { text: el.FloorName, value: el.id }
                            this.floors.push(element);

                        });
                        this.dialogAddFloor = false;
                    })
                    
                })
            
        },
        CreateArea() {
            axios
                .post(`${this.$store.state.URL_CREATE_AREA}`,
                    { areaName: this.newAreaName, floorId: this.floorValue })
                .then(() => {
                    axios.post(this.$store.state.URL_GET_AVAILABLE_AREAS, {
                    floorId: this.floorValue
                })
                    .then(res => {
                        res.data.forEach(el => {
                            var element = { text: el.AreaName, value: el.id }
                            this.areas.push(element);

                        });
                        this.dialogAddArea = false;
                    })
                    
                })
        },
        CreateNewArea(){
            this.dialogAddArea = true;
        },
        AlertDeleting(_id, _name) {
            this.id_deleting = _id;
            this.nameDeleting = _name;
            this.showDeleteAlert = true;
            
        },
        ConfirmDelete(){
            axios
                .post(`${this.$store.state.URL_DELETE_SENSOR}`, {
                    id: this.id_deleting
                })
                .then(() => {
                    this.showDeleteAlert = false;
                    this.Filter();
                })
        },
        CreateSensor() {
            axios
                .post(`${this.$store.state.URL_CREATE_NEW_SENSOR}`, {
                    dispenserArticleCodeId: this.dispenserArticleCode,
                    brandId: this.dispenserBrand,
                    companyId: this.client,
                    sensorName: this.dispenserName,
                    floorId: this.floorValue,
                    devEui: this.devEui.toUpperCase(),
                    dispenserModelId: this.dispenserArticleCode,
                    dispenserBrandId: this.dispenserBrand,
                    idArea: this.areaValue,
                    idConsumableBrand: this.consumableBrand,
                    idConsumableArticleCode: this.consumableArticleCode,
                    idBuilding: this.buildingValue

                })
                .then(res => {
                    console.log(res.data.message)
                    if (res.data.message === "Sensor created successfully") {
                        this.dialogAdd = false;
                        this.Filter();
                    }
                })
        },
        CreateNewBuilding() {
            this.dialogAddBuilding = true;
            this.newBuildingAddress = '';
            this.newBuildingName = '';
        },
        CreateNewFloor() {
            this.dialogAddFloor = true;
            this.newFloorName = '';
        },
        CreateNew() {
            this.floors.length = 0;
            this.areas.length = 0;
            this.sheetsVisible = false;
            this.Tipologiasensore = '';
            this.buildingValue = '';
            this.floorValue = '';
            this.maxPaper = 0;
            this.areaValue = '';
            this.devEui = '';
            this.dispenserName = '';
            this.dispenserBrand = '';
            this.dispenserArticleCode = '';
            this.consumableBrand = '';
            this.consumableArticleCode = '';
            this.client = '';
            this.buildingValue = '';
            this.overlay = true;
            this.showDispenserArticleCode = false;
            this.showArea = false;
            this.showFloor = false;
            this.showBuilding = false;
            this.consumableBrands.length = 0;
            this.showConsumableBrand = false;
            this.showConsumableArticleCode = false;
            this.LoadComponentData();
        },
        ClearFilter() {
            this.filterClient = '';
            this.filterBuildings.length = 0;
            this.filterBuilding = '';
            this.filterFloor = '';
            this.filterFloors.length = 0;
            this.filterArea = '';
            this.filterAreas.length = 0;
            this.filterSensors.length = 0;
            this.filterSensor = '';
            this.filterBuildings = [];
            this.filterFloors = [];
            this.filterAreas = [];
            this.filterSensors = [];
            this.Filter();
        },
        Filter() {
            this.overlay = true;
            axios
                .post(`${this.$store.state.URL_SENSORSMANAGEMENT}`,
                    {
                        id_company: this.$store.state.company,
                        'type': this.$route.params.type,
                        "companyId": this.$store.state.company,
                        "clientId": this.filterClient,
                        "buildingId": this.filterBuilding,
                        "floorId": this.filterFloor,
                        "areaId": this.filterArea,
                        "sensorId": this.filterSensor
                    })

                .then(res => {
                    this.items = res.data.map(el => ({
                        stato: el.DispenserType == 'Dispenser' ? el.percentuale == -1 ? "gray" : el.percentuale < 15 ? "red" : (el.percentuale < 40 ? "orange" : "green") : '',
                        DispenserType: el.DispenserImage,
                        percentuale: el.DispenserType == 'Dispenser' ? el.percentuale : '',
                        name: el.DisplayName,
                        Company: el.CompanyName,
                        BuildingName: el.BuildingName,
                        floor: el.FloorName,
                        status: el.DispenserType == 'Dispenser' ? 'on' : 'off',
                        battery: el.battery,
                        Area: el.AreaName,
                        sensorId: el.DispenserUnique,
                        id: el.id

                    }));

                    // this.items_all = this.items;
                    // this.filterBuilding_items = this.items.map(item => ({
                    //     text: item.BuildingName,
                    //     value: item.BuildingName
                    // }));
                    // this.filterBuilding_items.push({ text: "ALL", value: "ALL" });
                    // this.DownloadCompanyDatas();
                    // this.GetDispenserTypes();
                    this.overlay = false;
                })
        },
        LoadComponentData() {
            axios.post(this.$store.state.URL_GET_DISPENSER_BRAND)
                .then(res => {
                    res.data.forEach(el => {
                        var element = { text: el.DispenserBrandame, value: el.id }
                        this.dispenserBrands.push(element);
                    })
                    axios.post(this.$store.state.URL_GET_AVAILABLE_CLIENT)
                        .then(res => {
                            res.data.forEach(el => {
                                var element = { text: el.CompanyName, value: el.id }
                                this.clients.push(element);

                            })
                            axios.post(this.$store.state.URL_GET_CONSUMABLE_BRANDS)
                                .then(res => {
                                    res.data.forEach(el => {
                                        var element = { text: el.Brand, value: el.id }
                                        this.consumableBrands.push(element);
                                    })
                                    this.overlay = false;
                                    this.dialogAdd = true;
                                })
                                .catch(() => {
                                    this.overlay = false;
                                })
                        })
                        .catch(() => {
                            this.overlay = false;
                        })
                })
                .catch(() => {
                    this.overlay = false;
                });
        },
        GetDispenserTypes() {
            this.sensorTypes.length = 0;
            axios.post(this.$store.state.URL_DISPENSERTYPE, {
                "company": this.$store.state.company
            })
                .then(res => {
                    if (res.data.length > 0) {
                        res.data.forEach(el => {
                            this.sensorTypes.push({ text: el.DispenserTypeName, value: el });
                        })
                    }
                    else {
                        this.addButton = false;
                    }
                })
        },
        DownloadCompanyDatas() {
            axios.post(this.$store.state.URL_GET_AVAILABLE_CLIENT)
                .then(res => {
                    res.data.forEach(el => {
                        var element = { text: el.CompanyName, value: el.id }
                        this.filterClients.push(element);

                    })
                })
                .catch(() => {
                    this.overlay = false;
                    this.$router.push({ path: "/" });
                })
            axios.post(this.$store.state.URL_COMPANY_DATA, {
                "Company": this.$store.state.company
            })
                .then(res => {
                    this.$store.state.building = res.data
                    this.building_items.length = 0;
                    this.floor_items.length = 0;

                    this.$store.state.building.buildings.forEach(el => {
                        var found = false;
                        var c = 0;
                        el.floors.forEach(e => {
                            e.sensors.forEach(s => {
                                found = Object.values(s).includes("Dispenser")
                                if (found)
                                    c++;
                                console.log(e, found)
                            })

                        })

                        if (c > 0) {
                            if (this.$store.state.company == '1')
                                this.building_items.push({ text: el.BuildingName.replace('Zwetko Headquarter', 'Airport'), value: el });
                            else
                                this.building_items.push({ text: el.BuildingName, value: el });
                        }
                    })
                })
                .catch(() => {
                    //Redirect alla pagina di errore
                    //this.snackbar = true;
                })
        },
        CheckInvio(evt) {
            if (evt != null && evt != undefined) {

                evt = evt ? evt : window.event;
                var charCode = evt.which ? evt.which : evt.keyCode;
                if (charCode === 13) {
                    this.Login();
                }
            }
        },
        GetSensorsData() {
            this.overlay = true;
            axios
                .post(`${this.$store.state.URL_SENSORSMANAGEMENT}`,
                    { id_company: this.$store.state.company, 'type': this.$route.params.type })
                .then(res => {
                    this.items = res.data.map(el => ({
                        stato: el.DispenserType == 'Dispenser' ? el.percentuale == -1 ? "gray" : el.percentuale < 15 ? "red" : (el.percentuale < 40 ? "orange" : "green") : '',
                        DispenserType: el.DispenserImage,
                        percentuale: el.DispenserType == 'Dispenser' ? el.percentuale : '',
                        name: el.DisplayName,
                        Company: el.CompanyName,
                        BuildingName: el.BuildingName,
                        floor: el.FloorName,
                        status: el.DispenserType == 'Dispenser' ? 'on' : 'off',
                        battery: el.battery,
                        Area: el.AreaName,
                        sensorId: el.DispenserUnique,
                        id: el.id
                    }));

                    this.items_all = this.items;
                    this.filterBuilding_items = this.items.map(item => ({
                        text: item.BuildingName,
                        value: item.BuildingName
                    }));
                    this.filterBuilding_items.push({ text: "ALL", value: "ALL" });

                    this.DownloadCompanyDatas();
                    this.GetDispenserTypes();
                    this.overlay = false;
                })
                .catch(error => {
                    console.error('Error fetching sensor data:', error);
                    this.overlay = false; // Ensure overlay is removed even if there's an error
                });
        }
    },
    watch: {
        '$route.params.type': function (type) {
            console.log(type)
            var typologies = ['dispenser', 'washroom', 'button', 'air'];
            if (typologies.includes(type)) {
                this.GetSensorsData();
            }
        },
        client(value) {
            if (value != undefined &&
                value != null &&
                value != "" &&
                value != 0
            ) {
                this.showFloor = false;
                this.showArea = false;
                this.floors.length = 0;
                this.areas.length = 0;
                this.showBuilding = true;
                this.buildings.length = 0;
                this.buildingValue = '';

                axios.post(this.$store.state.URL_GET_AVAILABLE_BUILDINGS, {
                    companyId: value
                })
                    .then(res => {
                        res.data.forEach(el => {
                            var element = { text: el.BuildingName, value: el.id }
                            this.buildings.push(element);

                        });
                    })
            }
            else {
                this.showBuilding = false;
                this.buildings.length = 0;
                this.buildingValue = '';
            }
        },
        buildingValue(value) {
            if (value != undefined &&
                value != null &&
                value != "" &&
                value != 0
            ) {
                this.showFloor = true;
                this.floors.length = 0;
                this.floorValue = '';
                this.floors = [];
                axios.post(this.$store.state.URL_GET_AVAILABLE_FLOORS, {
                    buildingId: value
                })
                    .then(res => {
                        res.data.forEach(el => {
                            var element = { text: el.FloorName, value: el.id }
                            this.floors.push(element);
                        });

                    })
            }
            else {
                this.showFloor = false;
                this.floors.length = 0;
                this.floorValue = '';
            }
        },
        filterBuildingValue(value) {
            if (value == "ALL") {
                this.items = this.items_all;
            }
            else {
                this.items = this.items_all.filter(p => p.BuildingName == value);
            }
        },
        floorValue(value) {
            if (value != undefined &&
                value != null &&
                value != "" &&
                value != 0
            ) {
                this.showArea = true;
                this.areas.length = 0;
                this.areaValue = '';
                this.areas = [];
                axios.post(this.$store.state.URL_GET_AVAILABLE_AREAS, {
                    floorId: value
                })
                    .then(res => {
                        res.data.forEach(el => {
                            var element = { text: el.AreaName, value: el.id }
                            this.areas.push(element);
                        });

                    })
            }

        },
        dispenserBrand(value) {
            if (value != undefined &&
                value != null &&
                value != "" &&
                value != 0
            ) {
                this.showDispenserArticleCode = true;
                this.dispenserArticleCodes.length = 0;
                this.dispenserArticleCode = '';
                this.consumableArticleCode = '';
                this.consumableArticleCodes.length = 0;
                axios.post(this.$store.state.URL_GET_DISPENSER_CODES, {
                    idDispenserBrand: value
                })
                    .then(res => {
                        res.data.forEach(el => {
                            var element = { text: el.ArticleCode + " - " + el.Description, value: el.id }
                            this.dispenserArticleCodes.push(element);
                        });

                    })
            }
        },
        dispenserArticleCode(value) {
            if (value != undefined &&
                value != null &&
                value != "" &&
                value != 0
            ) {
                this.consumableArticleCode = '';
                this.consumableArticleCodes.length = 0;
                this.showConsumableBrand = true;
            }
        },
        consumableBrand(value) {
            if (value != undefined &&
                value != null &&
                value != "" &&
                value != 0
            ) {
                this.showConsumableArticleCode = true;
                this.consumableArticleCodes.length = 0;
                this.consumableArticleCode = '';
                axios.post(this.$store.state.URL_GET_CONSUMABLE_MODELS, {
                    consumableBrand: value,
                    dispenserId: this.dispenserArticleCode
                })
                    .then(res => {
                        res.data.forEach(el => {
                            var element = { text: el.ArtcleCode + " - " + el.Name, value: el.id }
                            this.consumableArticleCodes.push(element);
                        });

                    })
            }
        },
        Tipologiasensore(value) {
            this.sheetsVisible = false;
            if (value.DispenserPaperType == 'Foglietti') {
                this.sheetsVisible = true;
            }
            console.log(value)
        },
        filterClient(value) {
            if (value != undefined &&
                value != null &&
                value != "" &&
                value != 0
            ) {
                this.filterAreas.length = 0;
                this.filterArea = '';
                this.filterAreas = [];
                this.filterFloors.length = 0;
                this.filterFloor = '';
                this.filterFloors = []
                this.filterBuildings.length = 0;
                this.filterBuilding = '';
                this.filterBuildins = [];
                this.filterSensors.length = 0;
                this.filterSensor = '';
                this.filterSensors = [];
                var el = { text: "...", value: "" };
                this.filterBuildings.push(el);
                axios.post(this.$store.state.URL_GET_AVAILABLE_BUILDINGS, {
                    companyId: value
                })
                    .then(res => {
                        res.data.forEach(el => {
                            var element = { text: el.BuildingName, value: el.id }
                            this.filterBuildings.push(element);

                        });
                    })
            }
            else {
                this.filterAreas.length = 0;
                this.filterArea = '';
                this.filterAreas = [];
                this.filterFloors.length = 0;
                this.filterFloor = '';
                this.filterFloors = []
                this.filterBuildings.length = 0;
                this.filterBuilding = '';
                this.filterBuildins = [];
                this.filterSensors.length = 0;
                this.filterSensor = '';
                this.filterSensors = [];
            }
        },
        filterBuilding(value) {
            if (value != undefined &&
                value != null &&
                value != "" &&
                value != 0
            ) {
                this.filterAreas.length = 0;
                this.filterArea = '';
                this.filterAreas = [];
                this.filterFloors.length = 0;
                this.filterFloor = '';
                this.filterFloors = [];
                this.filterSensors.length = 0;
                this.filterSensor = '';
                this.filterSensors = [];
                var el = { text: "...", value: "" };
                this.filterFloors.push(el);
                axios.post(this.$store.state.URL_GET_AVAILABLE_FLOORS, {
                    buildingId: value
                })
                    .then(res => {
                        res.data.forEach(el => {
                            var element = { text: el.FloorName, value: el.id }
                            this.filterFloors.push(element);
                        });

                    })
            } else {
                this.filterAreas.length = 0;
                this.filterArea = '';
                this.filterAreas = [];
                this.filterFloors.length = 0;
                this.filterFloor = '';
                this.filterFloors = [];
                this.filterSensors.length = 0;
                this.filterSensor = '';
                this.filterSensors = [];
            }
        },
        filterFloor(value) {
            if (value != undefined &&
                value != null &&
                value != "" &&
                value != 0
            ) {
                this.filterAreas.length = 0;
                this.filterAreas = [];
                this.filterArea = '';
                this.filterSensors.length = 0;
                this.filterSensor = '';
                this.filterSensors = [];
                var el = { text: "...", value: "" };
                this.filterAreas.push(el);
                axios.post(this.$store.state.URL_GET_AVAILABLE_AREAS, {
                    floorId: value
                })
                    .then(res => {
                        res.data.forEach(el => {
                            var element = { text: el.AreaName, value: el.id }
                            this.filterAreas.push(element);
                        });

                    })
            }
            else {
                this.filterAreas.length = 0;
                this.filterAreas = [];
                this.filterArea = '';
                this.filterSensors.length = 0;
                this.filterSensor = '';
                this.filterSensors = [];
            }

        },
        filterArea(value) {
            if (value != undefined &&
                value != null &&
                value != "" &&
                value != 0
            ) {
                this.filterSensors.length = 0;
                this.filterSensor = '';
                this.filterSensors = [];
                var el = { text: "...", value: "" };
                this.filterSensors.push(el);
                axios.post(this.$store.state.URL_GET_SENSORS_COMPANY_BUILDING_FLOOR_AREA, {
                    companyId: this.filterClient,
                    buildingId: this.filterBuilding,
                    floorId: this.filterFloor,
                    areaId: value,
                    type: this.$route.params.type
                })
                    .then(res => {
                        res.data.forEach(el => {
                            var element = { text: el.DispenserUnique, value: el.id }
                            this.filterSensors.push(element);
                        });

                    })
            }
            else {
                this.filterSensors.length = 0;
                this.filterSensor = '';
                this.filterSensors = [];
            }
        }
    }
}
</script>