<template >
  <v-layout justify-center>
    <v-flex xs0 sm0 md1>
    </v-flex>
    <v-flex xs12 sm12 md10>
      <v-app id="app">
      <v-v-app-bar color="white" fixed app>
        <v-toolbar v-if="$route.path != '/'">
          <v-app-bar-nav-icon color="#234a98"
            @click.stop="drawer = !drawer" >
          </v-app-bar-nav-icon>
          <v-spacer></v-spacer>
            <!-- <img height="50px" :src="avatar"  /> -->
            <img height="50px" src="./assets/YT_logo_png.png" />
          <v-spacer></v-spacer>
          <v-avatar class="mr-4">
            <v-img src="./assets/blank-profile-picture-973460_1280.png" width="20%" />
          </v-avatar>
          <h4 class="mt-0"> {{ displayName }}</h4>
        </v-toolbar>
        </v-v-app-bar>
        

        <v-navigation-drawer style="background: linear-gradient(rgba(0, 156, 168, 1), rgba(33, 58, 143, 1));"  v-model="drawer" app left temporary v-if="$route.path != '/'">
            <!-- <v-layout>
              <v-flex xs12 sm12 md12>
                <v-btn style="box-shadow: none; margin-right: 10%;" color="#234a98" text><h2 style=" color: #FFFFFF;">ENG</h2></v-btn>
                <v-btn style="box-shadow: none;" color="#234a98" @click="$i18n.locale='it'" text ><h2 style=" color: #FFFFFF;">IT</h2></v-btn>
              </v-flex>
            </v-layout> -->
          <v-divider class="menuDivider" color="white"></v-divider>
          <v-list v-if="items_sensors.length > 0">
            <v-list-item
              value="true"
              v-for="(item, i) in items_sensors"
              :key="i"
              @click="Redirect(item.route != ''?item.route:item.href)"
              :disabled="item.disabled"
              :style="item.bold==1?'color: white;': ''"
              
            >
              <!-- <v-list-item-action>
                <v-icon :style="$route.path === item.route || $route.path.split('/',2)[1] === item.route .split('/',2)[1] ? 'color: #0b99a7' : 'color: white'" v-html="item.icon"></v-icon>
              </v-list-item-action> -->
              <v-list-item-content>
                <v-list-item-title class="no-wrap" :style="$route.path === item.route || ($route.path.split('/',2)[1] === item.route.split('/',2)[1] && ($route.path.split('/')[2] != undefined && $route.path.split('/')[2] != null && $route.path.split('/')[2] === item.route.split('/')[2]))? 'color: #FFFFFF' : 'color: ' + item.color" v-text="item.title"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-divider v-if="items_sensors.length > 0" class="menuDivider" color="white"></v-divider>
          <v-list v-if="items_alerts.length > 0">
            <v-list-item
              value="true"
              v-for="(item, i) in items_alerts"
              :key="i"
              @click="Redirect(item.route != ''?item.route:item.href)"
              :disabled="item.disabled"
              :style="item.bold==1?'color: white;': ''"
            >
              <!-- <v-list-item-action>
                <v-icon :style="$route.path === item.route || $route.path.split('/',2)[1] === item.route .split('/',2)[1] ? 'color: #0b99a7' : 'color: white'" v-html="item.icon"></v-icon>
              </v-list-item-action> -->
              <v-list-item-content>
                <v-list-item-title class="no-wrap" :style="$route.path === item.route || $route.path.split('/',2)[1] === item.route .split('/',2)[1] ? 'color: #FFFFFF' : 'color: ' + item.color" v-text="item.title"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-divider v-if="items_alerts.length > 0" class="menuDivider" color="white"></v-divider>
          <v-list v-if="items_dashboards.length>0">
            <v-list-item
              value="true"
              v-for="(item, i) in items_dashboards"
              :key="i"
              @click="Redirect(item.route != ''?item.route:item.href)"
              :disabled="item.disabled"
              :style="item.bold==1?'color: white;': ''"  
            >
              <!-- <v-list-item-action> -->
                <!-- <v-icon v-if="item.title != 'Smart Dispenser'" :style="$route.path === item.route || $route.path.split('/',2)[1] === item.route .split('/',2)[1] ? 'color: #0b99a7' : 'color: white'" v-html="item.icon"></v-icon> -->
                <!-- <v-img width="30px" src="./assets/dispenser_white.png" v-if="item.title == 'Smart Dispenser'" /> -->
              <!-- </v-list-item-action> -->
              <v-list-item-content>
                <v-list-item-title class="no-wrap" :style="$route.path === item.route || $route.path.split('/',2)[1] === item.route .split('/',2)[1] ? 'color: #FFFFFF' : 'color: ' + item.color" v-text="item.title"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-divider v-if="items_dashboards.length>0" class="menuDivider" color="white"></v-divider>
          <v-list v-if="items_manage.length > 0">
            <v-list-item
              value="true"
              v-for="(item, i) in items_manage"
              :key="i"
              @click="Redirect(item.route != ''?item.route:item.href)"
              :disabled="item.disabled"
            >
              <!-- <v-list-item-action>
                <v-icon :="$route.path === item.route || $route.path.split('/',2)[1] === item.route .split('/',2)[1] ? 'color: #0b99a7' : 'color: white'" v-html="item.icon"></v-icon>
              </v-list-item-action> -->
              <v-list-item-content>
                <v-list-item-title class="no-wrap" :style="$route.path === item.route || $route.path.split('/',2)[1] === item.route .split('/',2)[1] ? 'color: #FFFFFF' : 'color: ' + item.color"  v-text="item.title"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-divider v-if="items_dashboards.length>0" class="menuDivider" color="white"></v-divider>
          <v-list v-if="items_support.length > 0">
            <v-list-item
              value="true"
              v-for="(item, i) in items_support"
              :key="i"
              @click="item.MailTo != 1?Redirect(item.route != ''?item.route:item.href):MailTo(item.Email)"
              :disabled="item.disabled"
            >
              <!-- <v-list-item-action>
                <v-icon :style="$route.path === item.route || $route.path.split('/',2)[1] === item.route .split('/',2)[1] ? 'color: #0b99a7' : 'color: white'" v-html="item.icon"></v-icon>
              </v-list-item-action> -->
              <v-list-item-content>
                <v-list-item-title class="no-wrap" :style="$route.path === item.route || $route.path.split('/',2)[1] === item.route .split('/',2)[1] ? 'color: #FFFFFF' : 'color: ' + item.color"  v-text="item.title"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-list v-if="items_standard.length > 0">
            <v-list-item
              value="true"
              v-for="(item, i) in items_standard"
              :key="i"
              @click="Redirect(item.route != ''?item.route:item.href)"
              :disabled="item.disabled"
            >
              <!-- <v-list-item-action>
                <v-icon :style="$route.path === item.route || $route.path.split('/',2)[1] === item.route .split('/',2)[1] ? 'color: #0b99a7' : 'color: white'" v-html="item.icon"></v-icon>
              </v-list-item-action> -->
              <v-list-item-content>
                <v-list-item-title class="no-wrap" :style="$route.path === item.route || $route.path.split('/',2)[1] === item.route .split('/',2)[1] ? 'color: #FFFFFF' : 'color: ' + item.color"  v-text="item.title"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          </v-navigation-drawer>
          <router-view id="makeMeBlue"/>
      </v-app>
    </v-flex>
    <v-flex xs0 sm0 md1>
    </v-flex>
  </v-layout>
</template>
<script>
import axios from "axios";
  export default {
  name: 'App',
  data(){
    return{
      displayName: '',
      avatar: '',
      items_sensors: [],
      items_alerts: [],
      items_dashboards: [],
      items_manage: [],
      items_support: [],
      items_standard: [],
      items_sensor_en: [
        {title: "SENSORS", icon: "mdi-access-point-network", route: "/sensors", color: "#BFCCE4"},
      ],
      items_alerts_en: [
        {title: "ALERTS", icon:"mdi-alert", route: "alerts", color: "#BFCCE4"}
      ],
      items_dashboards_en: [
              {title: "SMART DISPENSER", icon: "mdi-access-point-network", route: "/smartdispenser", color: "#BFCCE4"},
              {title: "SMART BIN", icon: "mdi-delete-variant", route: "/smartbin", color: "#BFCCE4"},
              {title: "SMART CLEANING", icon: "mdi-motion-sensor", route: "/smartcleaning", color: "#BFCCE4"},
              {title: "AIR QUALITY", icon: "mdi-weather-windy", route: "/airquality", color: "#BFCCE4"},
              {title: "DASHBOARDS", icon: "mdi-monitor-dashboard", route: "/dashboards", color: "#BFCCE4"}],
      items_manage_en: [
        {title: "MANAGE", icon: "", route: "", color: "#BFCCE4"},
        {title: "Alerts", icon:"mdi-alert", route:"AlertsManagement", color: "#BFCCE4"},
        {title: "Users", icon: "mdi-account", route: "/manageusers", color: "#BFCCE4"},
        {title: "Buildings", icon: "mdi-domain", route: "/managebuildings", color: "#BFCCE4"},
        {title: "Sensors", icon: "mdi-access-point-network", route: "/managesensors", color: "#BFCCE4"},
      ],
      items_support_en: [
        {title: "Help Desk", icon: "mdi-account", route: "/helpdesk", color: "#BFCCE4"}
      ],
      items_standard_en:[
        {title: "Logout", icon: "mdi-logout-variant", route: "/Logout", color: "#BFCCE4"}
      ],
      drawer: true,
    }
  },
  created(){
    this.$vuetify.theme.themes.light.primary = '#234a98'
    axios.defaults.headers.common["x-api-key"] = "19ef07bf8e620d48.a38898e8-fd15-4523-9b87-6d63b6809c20";
    if(this.$i18n.locale == "en"){
      this.items_sensors = [];
      this.items_alerts = [];
      this.items_dashboards = [];
      this.items_manage = [];
      this.items_support = [];
      this.items_standard = [];
    }
    this.$on("welcome", () => {
      this.loadMenu();
    });
    
  },
  methods: {
    MailTo(email){
      window.location.href = 'mailto:' + email;
    },
    Redirect(_path){
      this.$router.push({ path: _path });
    },
    loadMenu(){
      this.avatar = this.$store.state.avatar;
      this.displayName = this.$store.state.displayName;
      this.items_sensors.length = 0;
      this.items_alerts.length = 0;
      this.items_dashboards.length = 0;
      this.items_manage.length = 0;
      this.items_support.length = 0;
      this.items_standard.length = 0;
      this.$store.state.menu.forEach(el=>{
        switch(el.category){
          case "SENSOR":
            this.items_sensors.push(el);
            break;
          case "ALERTS":
            this.items_alerts.push(el);
            break;
          case "DASHBOARDS":
            this.items_dashboards.push(el);
            break;
          case "MANAGE":
            this.items_manage.push(el);
            break;
          case "SUPPORT":
            this.items_support.push(el);
            break;
          case "STANDARD":
            this.items_standard.push(el);
            break;
        }
      })
    }

  },
  watch: {
    $route(to, from) {
      if(to.path == "/welcome" && from.path == "/"){
        this.loadMenu();
      }
    },
    '$store.state.menu': function(val){
      if(val.length != 0)
        this.loadMenu();
    },
    
  }

}
</script>
<style lang="scss">

@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"),
  url("./font/Montserrat-Regular.ttf") format("truetype");
}

.text_youtissue input{
  color: #234a98 !important;
}
.v-slider__thumb{
  cursor:pointer;
  height:42px;
  width:42px;
}
#app {
  font-family: Montserrat;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #234a98;
  // background: url(./assets/test_sfondo.png) no-repeat left center fixed !important;
  // background-size: cover;
}
v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--filled v-text-field--is-booted v-text-field--enclosed v-text-field--outlined{
  color: #234a98;
}
h1,h2,h3,h4,h5,h6,p{
  color: #234a98;
}
.v-card__text{
  color: #234a98;
}
.v-label{
  font-weight:bolder;
}
#input{
  color:#234a98;
}
.v-input{
  color: #234a98;
}
.v-input__slot{
  color: #234a98;
}
.v-list-item--link::before {
  color: #234a98
}
.vs-dropdown{
  color: #234a98;
}
.v-list-item__title{
  font-weight: bold;
  text-align: left;
}
v-input theme--light v-text-field v-text-field--is-booted v-text-field--enclosed v-text-field--outlined{
  color: #234a98;
}
nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #213A8F;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
#makeMeBlue .v-label {
  color:#234a98 !important;
}
// #makeMeBlue .v-icon {
//   color: #234a98 !important;
// }
#makeMeBlue .v-text-field--outlined fieldset {
  border: #234a98 1px solid !important; 
}
.menuDivider{
  border: 1px solid !important;
  color: #FFFFFF !important;
}
.v-text-field input{
  color: #234a98 !important;
}
.mdi-calendar::before{
  color: #234a98 !important;
}
.no-wrap {
  white-space: normal !important;
  overflow-wrap: break-word;
  word-wrap: break-word;
}
// .v-list-item__title, .v-list-item__subtitle{
//   color: #234a98 !important;
// }
</style>
