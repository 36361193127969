<template>
    <v-container >
        <v-dialog persistent max-width="600" v-model="dialogDispenser"> 
            <v-card>
                <v-card-title>
                <v-select
                    v-model="valueDispenser"
                    :items="lista_dispenser"
                    label="Select one or more sensor"
                    multiple
                    >
                    <template v-slot:selection="{ item, index }">
                        <v-chip v-if="index === 0">
                        <span>{{ item.text }}</span>
                        </v-chip>
                        <span
                        v-if="index === 1"
                        class="grey--text text-caption"
                        >
                        (+{{ valueDispenser.length - 1 }} others)
                        </span>
                    </template>
                    </v-select>
                    </v-card-title>
                    <v-card-text>
                        <apexchartComponent type="line" height="350" :options="chartOptions_bin" :series="series_dispenser" />
                    </v-card-text>
                    <v-card-actions >
                        <v-spacer></v-spacer>
                        <v-btn color="#234a98" style="color: white;" large flat @click="dialogDispenser = false">{{ $t('key010') }}</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        <v-dialog persistent max-width="600" v-model="dialogRotoli"> 
            <v-card>
                <v-card-title>
                <v-select
                    v-model="valueRotoli"
                    :items="lista_rotoli"
                    label="Select one or more sensor"
                    multiple
                    >
                    <template v-slot:selection="{ item, index }">
                        <v-chip v-if="index === 0">
                        <span>{{ item.text }}</span>
                        </v-chip>
                        <span
                        v-if="index === 1"
                        class="grey--text text-caption"
                        >
                        (+{{ valueRotoli.length - 1 }} others)
                        </span>
                    </template>
                    </v-select>
                    </v-card-title>
                    <v-card-text>
                        <apexchartComponent max-width="20%" type="bar" height="350" :options="chartOptions_bar" :series="series_rotoli" />
                    </v-card-text>
                    <v-card-actions >
                        <v-spacer></v-spacer>
                        <v-btn color="#234a98" style="color: white;" large flat @click="dialogRotoli = false">{{ $t('key010') }}</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        <v-dialog persistent max-width="600" v-model="dialogVuoto"> 
            <v-card>
                <v-card-title>
                <v-select
                    v-model="valueVuoto"
                    :items="lista_vuoto"
                    label="Select one or more sensor"
                    multiple
                    >
                    <template v-slot:selection="{ item, index }">
                        <v-chip v-if="index === 0">
                        <span>{{ item.text }}</span>
                        </v-chip>
                        <span
                        v-if="index === 1"
                        class="grey--text text-caption"
                        >
                        (+{{ valueVuoto.length - 1 }} others)
                        </span>
                    </template>
                    </v-select>
                    </v-card-title>
                    <v-card-text>
                        <apexchartComponent max-width="20%" type="bar" height="350" :options="chartOptions_bar" :series="series_vuoto" />
                    </v-card-text>
                    <v-card-actions >
                        <v-spacer></v-spacer>
                        <v-select
                        v-model="valueVuoto"
                        :items="lista_vuoto"
                        label="Select one or more sensor"
                        multiple
                        >
                        <template v-slot:selection="{ item, index }">
                            <v-chip v-if="index === 0">
                            <span>{{ item.text }}</span>
                            </v-chip>
                            <span
                            v-if="index === 1"
                            class="grey--text text-caption"
                            >
                            (+{{ valueVuoto.length - 1 }} others)
                            </span>
                        </template>
                        </v-select>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        <v-dialog persistent max-width="600" v-model="dialogBar"> 
            <v-card>
                <v-card-title>
                <v-select
                    v-model="valueBar"
                    :items="lista_bar"
                    label="Select one or more sensor"
                    multiple
                    >
                    <template v-slot:selection="{ item, index }">
                        <v-chip v-if="index === 0">
                        <span>{{ item.text }}</span>
                        </v-chip>
                        <span
                        v-if="index === 1"
                        class="grey--text text-caption"
                        >
                        (+{{ valueBar.length - 1 }} others)
                        </span>
                    </template>
                    </v-select>
                    </v-card-title>
                    <v-card-text>
                        <apexchartComponent max-width="20%" type="bar" height="350" :options="chartOptions_bar" :series="series_bar" />
                    </v-card-text>
                    <v-card-actions >
                        <v-spacer></v-spacer>
                        <v-btn color="#234a98" style="color: white;" large flat @click="dialogBar = false">{{ $t('key010') }}</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        <v-dialog persistent max-width="600" v-model="dialogStacked"> 
            <v-card>
                <v-card-title>
                <v-select
                    v-model="valueStacked"
                    :items="lista_stacked"
                    label="Select one sensor"
                    >
                    <template v-slot:selection="{ item, index }">
                        <v-chip v-if="index === 0">
                        <span>{{ item.text }}</span>
                        </v-chip>
                        <span
                        v-if="index === 1"
                        class="grey--text text-caption"
                        >
                        (+{{ valueStacked.length - 1 }} others)
                        </span>
                    </template>
                    </v-select>
                    </v-card-title>
                    <v-card-text>
                        <apexchartComponent max-width="20%" type="bar" height="350" :options="chartOptions_stacked" :series="series_stacked" />
                    </v-card-text>
                    <v-card-actions >
                        <v-spacer></v-spacer>
                        <v-btn color="#234a98" style="color: white;" large flat @click="dialogStacked = false">{{ $t('key010') }}</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        <v-dialog persistent max-width="600" v-model="dialogBin"> 
            <v-card>
                <v-card-title>
                <v-select
                    v-model="valueBin"
                    :items="lista_bin"
                    label="Select one or more Bin"
                    multiple
                    >
                    <template v-slot:selection="{ item, index }">
                        <v-chip v-if="index === 0">
                        <span>{{ item.text }}</span>
                        </v-chip>
                        <span
                        v-if="index === 1"
                        class="grey--text text-caption"
                        >
                        (+{{ valueBin.length - 1 }} others)
                        </span>
                    </template>
                    </v-select>
                    </v-card-title>
                    <v-card-text>
                        <apexchartComponent type="line" height="350" :options="chartOptions_bin" :series="series_bin" />
                    </v-card-text>
                    <v-card-actions >
                        <v-spacer></v-spacer>
                        <v-btn color="#234a98" style="color: white;" large flat @click="dialogBin = false">{{ $t('key010') }}</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-card class="mt-4">
                        <v-card-text class=text-center>
                <v-layout align-center  >
                    
                        <v-flex xs6 sm4 md4 class="mr-4">
                            <v-select
                                v-model="buildingValue"
                                :items="building_items"
                                label="Building"
                                outlined
                                >
                            </v-select>
                        </v-flex>
                        <v-flex xs6 sm2 md2>
                            <v-select
                                v-model="floorValue"
                                :items="floor_items"
                                label="Floor"
                                outlined
                                >
                            </v-select>
                        </v-flex>
                        <v-flex xs0 sm0 md2>
                        </v-flex>
                        <v-flex xs6 sm2 md2>
                        </v-flex>
                        <v-flex xs6 sm2 md2>
                        </v-flex>
                        <v-flex xs6 sm4 md4>
                            <v-menu
                            ref="menu"
                            v-model="menu"
                            :close-on-content-click="false"
                            :return-value.sync="date"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                outlined
                                v-model="date"
                                label="Select date range"
                                prepend-inner-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                            ></v-text-field>
                            </template>
                            <v-date-picker
                            v-model="date"
                            no-title
                            scrollable
                            locale="it"
                            range
                            >
                            <v-spacer></v-spacer>
                            <v-btn
                                text
                                color="primary"
                                @click="menu = false"
                            >
                                Cancel
                            </v-btn>
                            <v-btn
                                text
                                color="primary"
                                @click="$refs.menu.save(date)"
                            >
                                OK
                            </v-btn>
                            </v-date-picker>
                        </v-menu>
                        </v-flex>
                     
                </v-layout>
           </v-card-text>
         </v-card>
         <v-card width="100%">
            <v-layout justify-start class="mt-4 align-center justify-center" max-width="80%">
                <v-flex xs12 sm3 md3 class="mr-4" >
                    <v-card height="100%">
                        <v-card-text>
                            <v-list-item>
                                <v-list-item-avatar><v-img src="../assets/toilet_paper.png" /></v-list-item-avatar>
                                <v-list-item-content class="mb-0">
                                    <v-list-item-subtitle class="mt-0" style="font-size: 16px; line-height: 1;"><p>Toilet Paper</p>
                                        <p style="font-weight: bold; line-height: 1;">N° rolls {{ handPaperRolls }}</p></v-list-item-subtitle>
                                </v-list-item-content>
                                
                            </v-list-item>
                        </v-card-text>
                    </v-card>
                </v-flex>
                <v-flex xs12 sm3 md3 class="mr-4">
                    <v-card height="100%" >
                        <v-card-text>
                            <v-list-item>
                                <v-list-item-avatar><v-img src="../assets/folded_towels.png" /></v-list-item-avatar>
                                <v-list-item-content class="mb-0">
                                    <v-list-item-subtitle class="mt-0" style="font-size: 16px; line-height: 1;"><p>Folded Towels</p> 
                                        <p style="font-weight: bold; line-height: 1;">N° sheets {{ foldedTowels }}</p></v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-card-text>
                    </v-card>
                </v-flex>
                <v-flex xs12 sm3 md3 class="mr-4">
                    <v-card height="100%">
                        <v-card-text>
                            <v-list-item>
                                <v-list-item-avatar><v-img src="../assets/folded_towels.png" /></v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-subtitle class="mt-0" style="font-size: 16px; line-height: 1;"><p>Folded Towels</p>
                                        <p style="font-weight: bold; line-height: 1;">N° Cartons {{ (Number(foldedTowels) / 50).toFixed(2) }}</p></v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-card-text>
                    </v-card>
                </v-flex>
                <v-flex xs12 sm3 md3 class="mr-4">
                    <v-card height="100%">
                        <v-card-text>
                            <v-list-item>
                                <v-list-item-avatar><v-img src="../assets/toilet_paper.png" /></v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-subtitle class="mt-0" style="font-size: 16px; line-height: 1;"><p>Toilet Paper</p>
                                        <p style="font-weight: bold; line-height: 1;">N° Cartons {{ (Number(handPaperRolls) / 12).toFixed(2) }}</p></v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-card-text>
                    </v-card>
                </v-flex>
                <v-flex xs12 sm3 md3 class="mr-4">
                    <v-card height="100%">
                        <v-card-text>
                            <v-list-item>
                                <v-list-item-avatar><v-img src="../assets/image77.png" />"</v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-subtitle class="mt-0" style="font-size: 16px; line-height: 1;"><p>Washroom Freq.</p>
                                        <p style="font-weight: bold; line-height: 1;">Daily Average {{ averageUsers }}</p></v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-card-text>
                    </v-card>
                </v-flex>
            
            </v-layout>
        </v-card>

         <v-card class="mt-4">
             <v-card-text>
                <v-layout>
                    <v-flex xs6 sm6 md4>
                    <v-card raised class="mr-4">
                        <p style="margin-top: 15px; font-weight: bold; font-size: 16px">BIN WASTE LEVEL</p>
                        <apexchartComponent type="line" height="350" :options="chartOptions_bin" :series="series_bin" />
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-select
                            v-model="valueBin"
                            :items="lista_bin"
                            label="Select one or more Bin"
                            multiple
                            raised
                            outlined
                            >
                            <template v-slot:selection="{ item, index }">
                                <v-chip v-if="index === 0">
                                <span>{{ item.text }}</span>
                                </v-chip>
                                <span
                                v-if="index === 1"
                                class="grey--text text-caption"
                                >
                                (+{{ valueBin.length - 1 }} others)
                                </span>
                            </template>
                            </v-select>
                            
                        </v-card-actions>
                    </v-card>
                    </v-flex>
                    <v-flex xs6 sm6 md4>
                    <v-card raised class="mr-4">
                        <p style="margin-top: 15px; font-weight: bold; font-size: 16px">FULL - EMPTY DISPENSER</p>
                        <apexchartComponent max-width="20%" type="bar" height="350" :options="chartOptions_stacked" :series="series_stacked" />
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-select
                            v-model="valueStacked"
                            :items="lista_stacked"
                            label="Select one sensor"
                            raised
                            outlined
                            >
                            <template v-slot:selection="{ item, index }">
                                <v-chip v-if="index === 0">
                                <span>{{ item.text }}</span>
                                </v-chip>
                                <span
                                v-if="index === 1"
                                class="grey--text text-caption"
                                >
                                (+{{ valueStacked.length - 1 }} others)
                                </span>
                            </template>
                            </v-select>
                        </v-card-actions>
                    </v-card>
                    </v-flex>
                    <v-flex xs6 sm6 md4>
                    <v-card raised class="mr-4">
                        <p style="margin-top: 15px; font-weight: bold; font-size: 16px">WASHROOM USAGE FREQUENCY</p>
                        <apexchartComponent max-width="20%" type="bar" height="350" :options="chartOptions_barFrequency" :series="series_bar" />
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-select
                            v-model="valueBar"
                            :items="lista_bar"
                            label="Select one or more sensor"
                            multiple
                            raised
                            outlined
                            
                            >
                            <template v-slot:selection="{ item, index }">
                                <v-chip v-if="index === 0">
                                <span>{{ item.text }}</span>
                                </v-chip>
                                <span
                                v-if="index === 1"
                                class="grey--text text-caption"
                                >
                                (+{{ valueBar.length - 1 }} others)
                                </span>
                            </template>
                            </v-select>
                        </v-card-actions>
                    </v-card>
                    </v-flex>
                    
            </v-layout>
            <v-layout>
                <v-flex xs6 sm6 md4>
                    <v-card raised class="mr-4">
                        <p style="margin-top: 15px; font-weight: bold; font-size: 16px">DISPENSER LEVEL - 1 FLOOR</p>
                        <apexchartComponent type="line" height="350" :options="chartOptions_bin" :series="series_dispenser" />
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-select
                            v-model="valueDispenser"
                            :items="lista_dispenser"
                            label="Select one or more dispenser"
                            multiple
                            raised
                            outlined
                            >
                            <template v-slot:selection="{ item, index }">
                                <v-chip v-if="index === 0">
                                <span>{{ item.text }}</span>
                                </v-chip>
                                <span
                                v-if="index === 1"
                                class="grey--text text-caption"
                                >
                                (+{{ valueDispenser.length - 1 }} others)
                                </span>
                            </template>
                            </v-select>
                        </v-card-actions>
                    </v-card>
                    </v-flex>
                    <v-flex xs6 sm6 md4>
                    <v-card raised class="mr-4">
                        <p style="margin-top: 15px; font-weight: bold; font-size: 16px">PAPER CONSUMPTION </p>
                        <apexchartComponent max-width="20%" type="bar" height="350" :options="chartOptions_bar" :series="series_rotoli" />
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-select
                            v-model="valueRotoli"
                            :items="lista_rotoli"
                            label="Select one or more sensor"
                            multiple
                            raised
                            outlined
                            >
                            <template v-slot:selection="{ item, index }">
                                <v-chip v-if="index === 0">
                                <span>{{ item.text }}</span>
                                </v-chip>
                                <span
                                v-if="index === 1"
                                class="grey--text text-caption"
                                >
                                (+{{ valueRotoli.length - 1 }} others)
                                </span>
                            </template>
                            </v-select>
                        </v-card-actions>
                    </v-card>
                    </v-flex>
                    <v-flex xs6 sm6 md4>
                    <v-card raised class="mr-4">
                        <p style="margin-top: 15px; font-weight: bold; font-size: 16px">EMPTY DISPENSER EVENT</p>
                        <apexchartComponent max-width="20%" type="bar" height="350" :options="chartOptions_bar" :series="series_vuoto" />
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-select
                            v-model="valueVuoto"
                            :items="lista_vuoto"
                            label="Select one or more sensor"
                            multiple
                            raised
                            outlined
                            >
                            <template v-slot:selection="{ item, index }">
                                <v-chip v-if="index === 0">
                                <span>{{ item.text }}</span>
                                </v-chip>
                                <span
                                v-if="index === 1"
                                class="grey--text text-caption"
                                >
                                (+{{ valueVuoto.length - 1 }} others)
                                </span>
                            </template>
                            </v-select>
                        </v-card-actions>
                    </v-card>
                    </v-flex>
            </v-layout>
             </v-card-text>
         </v-card>
    </v-container>
</template>

<script>
// import AnimatedNumber from "animated-number-vue";
    export default{
        // components: {
        //     AnimatedNumber
        // },
        name: 'SmartBinView',
        data(){
            return{
                handPaperRolls: 0,
                foldedTowels: 0,
                users: 0,
                averageUsers: 0,
                menu: false,
                date: "",
                floor_items: [],
                floorValue: "",
                buildingValue: [],
                lista_bin: [],
                lista_stacked: [],
                valueBin:[],
                valueStacked: [],
                valueBar: [],
                valueVuoto: [],
                valueRotoli: [],
                valueDispenser: [],
                dialogBar: false,
                dialogBin: false,
                dialogStacked: false,
                dialogVuoto: false,
                dialogRotoli: false,
                dialogDispenser: false,
                building_items: [{text: "YouTissue HQ", value: {
                    floor: ["first", "second"],
                    handPaperRolls: 46,
                    foldedTowels: 350,
                    users: 84,
                    averageUsers: 23,
                    series_bin: [{text: "Cestino bagno uomini", value: {name: "Cestino bagno uomini", data: [0, 15, 20, 20, 50, 80, 0, 5,25,30,32,58,59,80,90,0]}},{text: "Cestino bagno donne", value:{name: "Cestino bagno donne", data: [10, 15, 20, 25, 50, 60, 80, 95,0,15,30,50,55,80,85,90]}},{text: "Cestino bagno ospiti", value:{name: "Cestino bagno ospiti", data: [5, 5, 8, 10, 12, 12, 20, 21,30,35,40,42,46,50,54,55]}} ],
                    series_stacked: [
                        {text: "Dispenser bagno donne", 
                        value:[{name: 'PIENO',data: [44, 55, 41, 67, 22, 43, 21, 49]},
                        {name: 'VUOTO', data: [66, 45, 59, 33, 78, 57, 79, 51]}]
                        },
                        {text: "Dispenser bagno uomini", 
                        value:[{name: 'PIENO',data: [95, 80, 55, 40, 10, 90, 81, 49]},
                        {name: 'VUOTO', data: [5, 20, 45, 60, 90, 10, 19, 51]}]
                        }],
                        series_bar: [{text: "Porta bagno uomini", value: {name: "Ingressi Porta bagno uomini",data: [5, 14, 0, 7, 22, 30, 45, 25, 17]}},{text: "Porta bagno donne primo piano", value: {name: "Ingressi Porta bagno donne primo piano",data: [10, 4, 20, 17, 12, 23, 50, 75, 17]}}],
                        series_dispenser: [{text: "V Fold Towels", value: {name: "V Fold Towels", data: [100,85,80,75,70,70,60,40,30,0,100,95,93,90,80,50]}}, {text: "Jumbo Toilet Paper", value: {name: "Jumbo Toilet Paper", data: [52,48,40,35,10,5,98,90,85,80,62,60,45,35,20,10]}}],
                        series_rotoli: [{text: "Rotolo carta bagno uomini", value: {name: "Rotolo carta bagno uomini", data: [1,0,0,2,5,3,2,0,1]}}],
                        series_vuoto: [{text: "Autocut Roll - Men Washroom", value: {name: "Autocut Roll - Men Washroom", data: [0,15,0,0,60,0,0,30,0]}}, {text: "Autocut roll -  Female Washroom", value: {name: "Autocut roll -  Female Washroom", data: [0,15,0,0,45,60,0,0,0]}}]
                    }},
                    {text: "YouTissue Milano", value:{
                        floor: ["first", "second", "third"],
                        handPaperRolls: 80,
                        foldedTowels: 620,
                        users: 174,
                        averageUsers: 58,
                        series_bin: [{text: "Cestino bagno ospiti", value:{name: "Cestino bagno ospiti", data: [5, 12, 21, 28, 34, 50, 80, 5,25,28,32,54,60,80,90,0]}},{text: "Cestino bagno dipendenti", value:{name: "Cestino bagno dipendenti", data: [1, 11, 27, 29, 42, 46, 48, 50,60,75,80,5,15,18,25,30]}}],
                        series_stacked: [
                        {text: "Dispenser bagno donne", 
                        value:[{name: 'PIENO',data: [44, 55, 41, 67, 22, 43, 21, 49]},
                        {name: 'VUOTO', data: [66, 45, 59, 33, 78, 57, 79, 51]}]
                        }],
                        series_bar: [{text: "Porta bagno uomini", value: {name: "Ingressi",data: [10, 7, 43, 45, 0, 0, 20, 58, 54]}}],
                        series_dispenser: [{text: "Dispenser sapone", value: {name: "Dispenser sapone", data: [100,85,80,75,70,70,60,40,30,0,100,95,93,90,80,50]}}],
                        series_rotoli: [{text: "Rotolo carta bagno uomini", value: {name: "Rotolo carta bagno uomini", data: [1,0,0,2,5,3,2,0,1]}}],
                        series_vuoto: [{text: "Dispenser sapone bagno uomini", value: {name: "Dispenser sapone bagno uomini", data: [0,15,0,0,60,0,0,30,0]}}]
                    }}],
                series_bin: [],
                series_stacked: [],
                series_bar: [],
                series_dispenser:[],
                series_rotoli: [],
                series_vuoto:[],
                chartOptions_bin: {
                    states:{
                        hover: {
                            filter: {
                                type: 'none',
                                value: 0,
                            }
                        }
                    },
                    colors: ['#BFCCE4', '#213A8F', '#008ffb'],
                    chart: {
                    toolbar: {
                        show: false,
                    },
                    type: 'line',
                    height: 350,
                    },  
                    responsive: [{
                    breakpoint: 480,
                    options: {
                        legend: {
                        position: 'right',
                        offsetX: -10,
                        offsetY: 0
                        }
                    }
                    }],
                    yaxis:{
                        labels: {
                            offset: 10,
                            style:{
                                colors: '#BFCCE4',
                            },
                            formatter: function (value) {
                                return Number(value).toFixed(0) + "%";
                            },
                        }
                    },
                    xaxis: {
                    colors: '#213A8F',
                    labels: {
                        style: {
                            colors: '#213A8F',
                            fontSize: '8px',
                            fontFamily: 'Helvetica, Arial, sans-serif',
                            fontWeight: 400,
                            cssClass: 'apexcharts-xaxis-label',
                        },
                        
                    },
                    categories: ['01-sept', '02-sept', '03-sept', '04-sept', '05-sept', '06-sept', '07-sept', '08-sept', '09-sept',
                                    '10-sept', '11-sept', '12-sept', '13-sept', '14-sept', '15-sept', '16-sept'
                    ],
                },
                    
                    fill: {
                    opacity: 1
                    },
                    legend: {
                    position: 'bottom',
                    offsetX: 0,
                    offsetY: 50
                    },
                },
                chartOptions_stacked: {
                    states:{
                        hover: {
                            filter: {
                                type: 'none',
                                value: 0,
                            }
                        }
                    },
                    colors:['#BFCCE4', '#213A8F'],
                    chart: {
                        
                    toolbar: {
                        show: false,
                    },
                    type: 'bar',
                    height: 350,
                    stacked: true,
                    stackType: '100%'
                    },  
                    responsive: [{
                    breakpoint: 480,
                    options: {
                        legend: {
                        position: 'bottom',
                        offsetX: -10,
                        offsetY: 0
                        }
                    }
                    }],
                    yaxis:{
                        labels: {
                            offset: 10,
                            style:{
                                colors: '#BFCCE4',
                            },
                            formatter: function (value) {
                                return Number(value).toFixed(0) + "%";
                            },
                        }
                    },
                    xaxis: {
                    colors: '#213A8F',
                    labels: {
                        style: {
                            colors: '#213A8F',
                            fontSize: '8px',
                            fontFamily: 'Helvetica, Arial, sans-serif',
                            fontWeight: 400,
                            cssClass: 'apexcharts-xaxis-label',
                        },
                        
                    },
                    categories: ['01-sept', '02-sept', '03-sept', '04-sept', '05-sept', '06-sept', '07-sept', '08-sept', '09-sept'],
                },
                    fill: {
                    opacity: 0.7
                    },
                    legend: {
                    position: 'bottom',
                    offsetX: 0,
                    offsetY: 50
                    },
                },
                chartOptions_bar: {
                    states:{
                        hover: {
                            filter: {
                                type: 'none',
                                value: 0,
                            }
                        }
                    },
                    colors: ['#BFCCE4', '#213A8F', '#008ffb'],
                    chart: {
                        toolbar: {
                        show: false,
                    },
                    height: 350,
                    type: 'line',
                    zoom: {
                        enabled: false
                    }
                    },
                    dataLabels: {
                    enabled: false
                    },
                    stroke: {
                    curve: 'straight'
                    },
                    grid: {
                    
                    },
                    yaxis:{
                        labels: {
                            offset: 10,
                            style:{
                                colors: '#BFCCE4',
                            },
                            formatter: function (value) {
                                return Number(value).toFixed(0);
                            },
                        }
                    },
                    xaxis: {
                    colors: '#213A8F',
                    labels: {
                        style: {
                            colors: '#213A8F',
                            fontSize: '8px',
                            fontFamily: 'Helvetica, Arial, sans-serif',
                            fontWeight: 400,
                            cssClass: 'apexcharts-xaxis-label',
                        },
                        
                    },
                    categories: ['01-sept', '02-sept', '03-sept', '04-sept', '05-sept', '06-sept', '07-sept', '08-sept', '09-sept'],
                },

                },
                chartOptions_barFrequency: {
                    states:{
                        hover: {
                            filter: {
                                type: 'none',
                                value: 0,
                            }
                        }
                    },
                    colors: ['#BFCCE4', '#213A8F', '#008ffb'],
                    chart: {
                        toolbar: {
                        show: false,
                    },
                    height: 350,
                    type: 'line',
                    zoom: {
                        enabled: false
                    }
                    },
                    dataLabels: {
                    enabled: false
                    },
                    stroke: {
                    curve: 'straight'
                    },
                    grid: {
                    
                    },
                    yaxis:{
                        labels: {
                            offset: 10,
                            style:{
                                colors: '#BFCCE4',
                            },
                            formatter: function (value) {
                                return Number(value).toFixed(0);
                            },
                        }
                    },
                    xaxis: {
                    colors: '#213A8F',
                    labels: {
                        style: {
                            colors: '#213A8F',
                            fontSize: '8px',
                            fontFamily: 'Helvetica, Arial, sans-serif',
                            fontWeight: 400,
                            cssClass: 'apexcharts-xaxis-label',
                        },
                        
                    },
                    categories: ['01-sept', '02-sept', '03-sept', '04-sept', '05-sept', '06-sept', '07-sept', '08-sept', '09-sept'],
                },

                },
            }
        },
        watch:{
            buildingValue(val){
                this.valueBin.length = 0;
                this.valueBar.length = 0;
                this.valueStacked.length = 0;
                this.valueVuoto.length = 0;
                this.valueRotoli.length = 0;
                this.valueDispenser.length =0;
                this.series_bin.length = 0;
                this.series_stacked.length = 0;
                this.series_bar.length = 0;
                this.series_dispenser.length = 0;
                this.series_rotoli.length = 0;
                this.series_vuoto.length = 0;
                this.floor_items = val.floor;
                this.floorValue = val.floor[0];
                this.handPaperRolls = val.handPaperRolls;
                this.foldedTowels = val.foldedTowels;
                this.users = val.users;
                this.averageUsers = val.averageUsers;
                this.series_bin.push(val.series_bin[0].value);
                this.lista_bin = val.series_bin;
                this.lista_stacked = val.series_stacked;
                this.lista_bar = val.series_bar;
                this.lista_vuoto = val.series_vuoto;
                this.lista_rotoli = val.series_rotoli;
                this.lista_dispenser = val.series_dispenser;
                this.series_stacked.push(val.series_stacked[0].value[0]);
                this.series_stacked.push(val.series_stacked[0].value[1]);
                this.series_bar.push(val.series_bar[0].value)
                this.series_dispenser.push(val.series_dispenser[0].value);
                this.series_rotoli.push(val.series_rotoli[0].value);
                this.series_vuoto.push(val.series_vuoto[0].value);
            },
            valueBin(val){
                this.series_bin.length = 0;
                val.forEach(el=>{
                        this.series_bin.push({data: el.data, name: el.name})
                })
            },
            valueStacked(val){
                console.log(val);
                this.series_stacked.length = 0;
                this.series_stacked.push(val[0]);
                this.series_stacked.push(val[1]);
            },
            valueBar(val){
                console.log(val);
                this.series_bar.length = 0;
                val.forEach(el=>{
                    this.series_bar.push(el);
                })
            },
            valueVuoto(val){
                this.series_vuoto.length = 0;
                val.forEach(el=>{
                    this.series_vuoto.push(el);
                })
            },
            valueRotoli(val){
                this.series_rotoli.length = 0;
                val.forEach(el=>{
                    this.series_rotoli.push(el);
                })
            },
            valueDispenser(val){
                this.series_dispenser.length = 0;
                val.forEach(el=>{
                    this.series_dispenser.push(el);
                })
            },
        },
        mounted(){
            this.buildingValue = this.building_items[0].value;
            console.log(this.chartOptions_bin)
        },
    }
</script>
<style >
  .miochart.apexcharts-bar-area:hover { fill: #213A8F !important; }
  .v-select__selection,
    .v-select__selection--comma,
    .v-select.v-text-field input {
    color: #213A8F !important;
    }
    .v-icon.v-incon{
        color: #213A8F !important;
    }
    #changeMyColor .v-text-field--outlined fieldset {
        border: #BFCCE4 1px solid !important; 
    }
    .mt-4 mb-4 mr-4 ml-4 v-btn v-btn--text theme--light v-size--default{
        border: #213A8F 1px solid !important;
    }
  </style>
  

