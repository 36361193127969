<template>
  <v-container>
    <v-data-table
          v-bind:headers="headers"
          :items="items"
          :hide-default-footer="true"
          class="elevation-1"
        >
           <template v-slot:item="{ item }">
           <tr @click="Grafici()">
                <td><v-icon large color="black">mdi-motion-sensor</v-icon></td>
                <td><v-icon :style="item.stato == 'red' ? 'color: red;' : 'color: green;'">mdi-checkbox-blank-circle</v-icon></td>
                <td>{{ item.name }}</td>
                <td>{{ item.building }}</td>
                <td>{{ item.piano }}</td>
                <td><v-icon :style="item.signal == 'ko' ? 'color: red;' : 'color: green;'" >mdi-wifi</v-icon></td>
           </tr>
           </template>
        </v-data-table>
  </v-container>
</template>

<script>

export default {
  name: 'HomeView',
  data(){
    return{
      pagination: { 'sortBy': 'name', 'descending': true, rowsPerPage: 25 },
      selected: [],
      items: [{ name: "Porta bagno proprietà", piano: "terzo piano", building: "YouTissue", stato: "green", signal: "ok"},
              { name: "Porta bagno dipendenti",  piano: "primo piano", building: "YouTissue", stato: "red", signal: "ok"},
              { name: "Porta bagno dipendenti", piano: "terzo piano", building: "YouTissue", stato: "green", signal: "ko"},
              { name: "Porta bagno ospiti", piano: "terzo piano", building: "YouTissue", stato: "red", signal: "ok"}],
      headers: [
        { text: "Type", align: "center", value: "type"},
        { text: "State", align: "center", value: "stato" },
        { text: "Name", align: "center", value: "name" },
        { text: "Building", align: "center", value: "building" },
        { text: "Floor", align: "center", value: "piano" },
        { text: "Signal", align: "center", value: "signal" } 
      ]
    }
  },
  methods: {
    Grafici(){
      this.$router.push({ path: `/grafici` });
    }
  }
}
</script>
