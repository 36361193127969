<template>
    <v-container>
        <v-card class="mt-4">
            <v-layout>
                <v-flex xs12 sm12 md6>
                    <h2>Dati puntuali qui</h2>
                    <v-card class="ml-1" >
                        <v-card-text class="text-left">
                            <h3>Sensor id:</h3> {{ sensor_id }}
                            <h3>Sensor name:</h3> Zeta_first_PiegaVMarEco
                            <h3>Paper consumption:</h3> 1400
                            <h3>Box consumption</h3> 2.4
                        </v-card-text>
                    </v-card>
                </v-flex>
                <v-flex xs12 sm12 md6>
                    <apexchartComponent max-width="20%" type="bar" height="350" :options="chartOptions_stacked" :series="series_stacked" />
                </v-flex>
            </v-layout>
        </v-card>
    </v-container>
</template>

<script>
import axios from "axios";
// import AnimatedNumber from "animated-number-vue";
    export default{
        data(){
            return{
                lista_stacked: [],
                valueStacked: [],
                series_stacked: [{
                        name: 'Sensor',
                        data: []
                    }],
                chartOptions_stacked: {
                    colors:['#0ada11', '#ff0000'],
                    chart: {
                        
                    toolbar: {
                        show: false,
                    },
                    type: 'bar',
                    height: 350,
                    stacked: true,
                    stackType: '100%'
                    },  
                    responsive: [{
                    breakpoint: 480,
                    options: {
                        legend: {
                        position: 'bottom',
                        offsetX: -10,
                        offsetY: 0
                        }
                    }
                    }],
                    xaxis: {
                    categories: ["Sensor"],
                    },
                    fill: {
                    opacity: 1
                    },
                    legend: {
                    position: 'right',
                    offsetX: 0,
                    offsetY: 50
                    },
                },
                sensor_id: "",
            }
        },
        mounted(){
            this.sensor_id = this.$route.params.id;
            console.log(this.sensor_id)
            this.GetData(new Date().toISOString().toString().substr(0,10), this.sensor_id);
        },
        methods: {
            GetData(value ,sensor){
                console.log(sensor);
                var nextDay = new Date((Number(value.replaceAll("-", ""))+1).toString().substr(0,4) + "-" + 
                                    (Number(value.replaceAll("-", ""))+1).toString().substr(4,2) + "-" +
                                    (Number(value.replaceAll("-", ""))+1).toString().substr(6,2)).toISOString().toString().substr(0,10);
                var today = new Date((Number(value.replaceAll("-", ""))).toString().substr(0,4) + "-" + 
                                    (Number(value.replaceAll("-", ""))).toString().substr(4,2) + "-" +
                                    (Number(value.replaceAll("-", ""))).toString().substr(6,2)).toISOString().toString().substr(0,10);
                axios.post("https://api.akenza.io/v3/devices/" + this.sensor_id + "/query",
                {
                "limit": 1,
                "timestamp": {
                    "gte": today,
                    "lte": nextDay
                },
                "topic": "default",
                "skip": 0
                }
                ).
                then(res=> {
                    var valore = 100-((Number(res.data[0].data.tof_distance) * 100)/340).toFixed(0);
                    this.series_stacked.length = 0;
                    this.series_stacked.push({name: "Full",data: [valore > 100? 100 : valore < 0 ? 0 : valore]});
                    this.series_stacked.push({name: "Empty",data: [100-valore < 0 ? 0 : valore > 100 ? 100 : valore]});
                    
                    console.log(this.series_stacked);
                })
                .catch(err=>{
                    console.log(err);
                })
            }
        },
    }
</script>