import { render, staticRenderFns } from "./SmartDispenser.vue?vue&type=template&id=ae8a53fe&scoped=true"
import script from "./SmartDispenser.vue?vue&type=script&lang=js"
export * from "./SmartDispenser.vue?vue&type=script&lang=js"
import style0 from "./SmartDispenser.vue?vue&type=style&index=0&id=ae8a53fe&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ae8a53fe",
  null
  
)

export default component.exports