<template>
    <v-container>
        <v-card>
            <v-data-table
            v-bind:headers="headers"
            :items="items"
            :hide-default-footer="true"
            disable-pagination
            class="elevation-1"
            >
                <template v-slot:item="{ item }">
                    <tr>
                        <td></td>
                        <td>{{ item.name }}</td>
                        <td>{{ item.Company }}</td>
                        <td>{{ item.Address }}</td>
                        <td>{{ item.Sensors }}</td>
                        <td>
                            <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
                            <v-icon small class="mr-2" @click="editItem(item)">mdi-delete</v-icon>
                        </td>
                    </tr>
                </template>
            </v-data-table>
        </v-card>
    </v-container>
</template>
<script>
    import axios from "axios";
     export default {
        data(){
            return{
                headers: [{},{ text: "Name", align: "center", value: "name"},
                            { text: "Company", align: "center", value: "Company"},
                            { text: "Address", align: "center", value: "address"},
                            { text: "Sensors", align: "center", value: "Sensors"},
                            { text: "Actions", align: "center", value: ""},{}
                            ],
                items: [{name:  'Youtissue HQ', Company: "Youtissue", Sensors: 45},
                        {name: 'YouTissue Dublin', Company: "Youtissue", Sensors: 16},
                        {name: 'YouTissue Moscow', Company: "Youtissue", Sensors: 4},
                        ]
            }
        },
        mounted(){
            this.items = [];
            axios.
                post(this.$store.state.URL_BUILDINGSMANAGEMENT)
                .then(res=>{
                    res.data.map(el=>{
                        var element = {}
                        element = {
                            name: el.BuildingName,
                            Company: el.CompanyName,
                            address: el.Address,
                            Sensors: el.Sensors,
                        }
                    this.items.push(element);
                    })
                })
        }
     }
</script>
