import Vue from 'vue'
import VueRouter from 'vue-router'
import LoginHome from '../views/LoginHome.vue'
// import HomeView from '../views/HomeView.vue'
import ElencoSensoriView from '../views/SmartDispenser.vue'
import SensorView from '../views/SensorView.vue'
import WelcomeView from '../views/WelcomeView.vue'
import SmartBinView from '../views/SmartBinView.vue'
import SmartDispenser from '../views/SmartDispenser.vue'
import DispenserView from '../views/DispenserView.vue'
import BinView from '../views/BinView.vue'
import DoorView from '../views/DoorView.vue'
import AirView from '../views/AirView.vue'
import DashboardsView from '../views/DashboardsView.vue'
import AlertsView from '../views/AlertsView.vue'
// import UnderConstructionView from '../views/UnderCostructionView.vue'
import UsersView from '../views/UsersView.vue'
import BuildingsView from '../views/BuildingsView.vue'
import ManageSensorsView from '../views/ManageSensorsView.vue'
import WhasroomFrequencyView from '../views/WashroomFrequencyView.vue'
import SensorDetailsView from '../views/SensorDetailsView.vue'
import AlertsManagementView from '../views/AlertsManagementView.vue'
import AirQualityView from '../views/AirQualityView.vue'
import LogoutView from '../views/LogoutView.vue'
import SmartButtonView from '../views/SmartButtonView.vue'
import ResetPasswordView from '@/views/ResetPasswordView.vue'
// import { component } from 'vue/types/umd'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'LoginHome',
    component: LoginHome
  },
  {
    path: '/ResetPassword/:email/:id',
    name: 'ResetPasswordView',
    component: ResetPasswordView
  },
  {
    path: '/Logout',
    name: 'LogoutView',
    component: LogoutView
  },
  {
    path: '/airquality',
    name: 'AirQualityView',
    component: AirQualityView
  },
  {
    path: '/AlertsManagement',
    name: 'AlertsManagementView',
    component: AlertsManagementView
  },
  {
    path: '/SensorDetailsView/:id',
    name: 'SensorDetailsView',
    component: SensorDetailsView
  },
  {
    path: '/welcome',
    name: 'WelcomeView',
    component: WelcomeView
  },
  {
    path: '/manageusers',
    name: 'UsersView',
    component: UsersView
  },
  {
    path: '/managebuildings',
    name: 'BuildingsView',
    component: BuildingsView
  },
  {
    path: '/managesensors/:type',
    name: 'ManageSensorsView',
    component: ManageSensorsView
  },
  {
    path: '/alerts',
    name: 'AlertsView',
    component: AlertsView
  },
  {
    path: '/air',
    name: 'AirView',
    component: AirView
  },
  {
    path: '/door',
    name: 'DoorView',
    component: DoorView
  },
  {
    path: '/bin',
    name: 'BinView',
    component: BinView
  },
  {
    path: '/dispenser',
    name: 'DispenserView',
    component: DispenserView
  },
  {
    path: '/sensors',
    name: 'SensorView',
    component: SensorView
  },
  {
    path: '/sensori',
    name: 'ElencoSensoriView',
    component: ElencoSensoriView
  },
  {
    path: '/smartbin',
    name: 'SmartBinView',
    component: SmartBinView
  },
  {
    path: '/smartdispenser',
    name: 'SmartDispenser',
    component: SmartDispenser
  },
  {
    path: '/dashboards',
    name: 'DashboardsView',
    component: DashboardsView
  },
  {
    path: '/smartcleaning',
    name: 'WhasroomFrequencyView',
    component: WhasroomFrequencyView
  },
  {
    path: '/smartButton',
    name: 'SmartButtonView',
    component: SmartButtonView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
