<template>
  <v-container>
    <v-overlay v-model="overlay" color="white" opacity="1" persistent>
      <v-container style="text-align: center">
        <v-progress-circular :size="50" color="#234a98" indeterminate></v-progress-circular>
        <p class="pt-2" style="font-size: 14px; color: #234a98">Loading...</p>
      </v-container>
    </v-overlay>
  </v-container>
</template>

<script>
export default {
  name: 'LogoutView',
  created() {
    localStorage.clear();
    this.overlay = false;
    this.$router.push({ path: "/" });
  },
  data() {
    return {
      overlay: false,
    };
  },
};
</script>