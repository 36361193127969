<template>
    <v-container>
        <v-dialog persistent max-width="600" v-model="dialogError" :z-index="5000">
            <v-card>
                <v-card-title>Error!</v-card-title>
                <v-card-text>
                    <h3>The passwords don't match</h3>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text color="#213A8F" @click="dialogError = false">OK</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-form v-model="valid">
            <v-layout style="justify-content: center;" class="text-xs-center" max-width="600">
                <v-card>
                    <v-card-title>
                        Reset requested for: {{ email }}
                    </v-card-title>
                    <v-card-title>
                        Enter your new password in the fields below (make sure both password are the same in order to proceed)
                    </v-card-title>
                    <v-card-text>
                        <v-text-field label="New password" v-model="newpwd1" />
                        <v-text-field label="Confirm new password" v-model="newpwd2" :rules="[v => v == newpwd1 || 'le password non combaciano']" />
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="#234a98" text @click="SaveNewPassword()" :disabled="!valid">Save</v-btn>
                    </v-card-actions>
                </v-card>
            </v-layout>
        </v-form>
        <v-snackbar v-model="snackbar" :timeout="timeout" color="red accent-2">
            {{ snackbarText }}

            <template v-slot:action="{ attrs }">
                <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
                    OK
                </v-btn>
            </template>
        </v-snackbar>
        <v-snackbar v-model="snackbarGreen" :timeout="timeout" color="green accent-2">
            {{ snackbarGreenText }}

            <template v-slot:action="{ attrs }">
                <v-btn color="white" text v-bind="attrs" @click="$router.push({ path: '/' });">
                    OK
                </v-btn>
            </template>
        </v-snackbar>
    </v-container>
</template>
<script>
import axios from "axios";
export default {
    data() {
        return {
            valid: false,
            newpwd1: '',
            newpwd2: '',
            snackbar: false,
            timeout: 5000,
            id: '',
            email: '',
            snackbarText: '',
            snackbarGreen: false,
            snackbarGreenText: '',
            dialogError: false

        }
    },
    created() {
        this.email = atob(this.$route.params.email);
        this.id = atob(this.$route.params.id);
    },
    methods: {
        SaveNewPassword() {
            if (this.newpwd1 === this.newpwd2) {
                //chiamata alla funzione
                axios.post('https://xilw23kxbbqq7wfjkypqnhwrfe0vreem.lambda-url.eu-central-1.on.aws/', {
                    id: this.id,
                    new: this.newpwd1,
                    old: "resetYoutissue"
                })
                    .then(res => {
                        if (res.data === 'updated') {
                            this.snackbarGreenText = '';
                            this.snackbarGreen = true;
                            this.snackbarGreenText = 'Password changed successfully';
                            this.dialog = false;
                        }
                        else {
                            this.snackbarText = '';
                            this.snackbarText = res.data;
                            this.snackbar = true;
                        }
                    })
                    .catch(() => {
                        this.snackbar = true;
                        this.testoSnackbar = 'An error occured saving your new password. Please try again or contact the support team.'
                    })
            }
            else{
                this.dialogError = true;
            }
        }

    }
}


</script>