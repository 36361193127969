<template>
    <v-container>
        <v-overlay v-model="overlay" color="white" opacity="1" persistent>
            <v-container style="text-align: center">
                <v-progress-circular :size="50" color="#234a98" indeterminate></v-progress-circular>
                <p class="pt-2" style="font-size: 14px; color: #234a98">
                    Loading...
                </p>
            </v-container>
        </v-overlay>
        <v-dialog v-model="scelta" max-width="800" persistent>
            <v-card class="mb-4" width="100%" flat>
                <v-card-title>
                    Select one smart dispenser to continue
                </v-card-title>
                <v-card-text>
                    <v-layout class="mt-4" id="makeMeBlue">
                        <v-flex xs6 sm3 md3>
                            <v-select class="v-select" v-model="client" :items="clients" label="Client"
                                outlined >
                            </v-select>
                        </v-flex>
                        <v-flex xs6 sm3 md3>
                            <v-select class="ml-2" v-model="building" :items="buildings" label="Building"
                                outlined :disabled="buildingActive">
                            </v-select>
                        </v-flex>
                        <v-flex xs6 sm3 md3>
                            <v-select class="ml-2" v-model="floor" :items="floors" label="Floor" outlined
                                 :disabled="floorActive">
                            </v-select>
                        </v-flex>
                        <v-flex xs6 sm3 md3>
                            <v-select class="ml-2" v-model="area" :items="areas" label="Area"
                                outlined :disabled="areaActive">
                            </v-select>
                        </v-flex>
                        <v-flex xs6 sm3 md3>
                            <v-select class="ml-2" v-model="sensorsValue" :items="sensor_items" label="Sensor" outlined
                                :key="s" :disabled="sensorActive">
                            </v-select>
                        </v-flex>
                    </v-layout>
                </v-card-text>
                <v-card-actions>
                    <v-btn text color="#213A8F"
                        @click="scelta = false; $router.push({ path: `/welcome` });">BACK</v-btn>
                    <v-btn text color="#213A8F" :disabled="okActive" @click="LoadData()">OK</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-card class="mb-4" width="100%" flat v-show="!scelta">
            <v-card-text>
                <v-layout class="mt-4" id="changeMyColor">
                    <v-flex xs6 sm3 md3>
                        <v-select class="v-select" v-model="client" :items="clients" label="Client"
                            outlined >
                        </v-select>
                    </v-flex>
                    <v-flex xs6 sm3 md3>
                        <v-select class="ml-2" v-model="building" :items="buildings" label="Building" outlined :key="b">
                        </v-select>
                    </v-flex>
                    <v-flex xs6 sm3 md3>
                        <v-select class="ml-2" v-model="floor" :items="floors" label="Floor" outlined :key="f"
                            :disabled="floorActive">
                        </v-select>
                    </v-flex>
                    <v-flex xs6 sm3 md3>
                        <v-select class="ml-2" v-model="area" :items="areas" label="Area"
                            outlined :disabled="areaActive">
                        </v-select>
                    </v-flex>
                    <v-flex xs6 sm3 md3>
                        <v-select class="ml-2" v-model="sensorsValue" :items="sensor_items" label="Sensor" outlined
                            :key="s" :disabled="sensorActive">
                        </v-select>
                    </v-flex>
                    <v-flex xs6 sm3 md3 class="ml-2">
                        <v-menu ref="menu" v-model="menu" :close-on-content-click="false" :return-value.sync="date"
                            transition="scale-transition" offset-y min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field outlined v-model="date" label="Select date range"
                                    prepend-inner-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                            </template>
                            <v-date-picker v-model="date" no-title scrollable locale="it" range :max="maxDate"
                                :min="minDate" @click="SetMinDate">
                                <v-spacer></v-spacer>
                                <v-btn text color="primary" @click="menu = false">
                                    Cancel
                                </v-btn>
                                <v-btn text color="primary" @click="$refs.menu.save(date); GetDataByDate()">
                                    OK
                                </v-btn>
                            </v-date-picker>
                        </v-menu>
                    </v-flex>
                </v-layout>
                <hr class="mt-4" style="border-top: solid 1px; color: #BFCCE4" />
                <v-layout v-show="visualizza_dati && !scelta">
                    <v-flex xs2 sm6 md3 class="mt-6 mb-4 mr-0 ml-0 justify-left">
                        <h2 style="font-weight: bold;">Quick filters: </h2>
                    </v-flex>
                    <v-flex xs2 sm6 md3>
                        <v-btn text @click="ChangeDate('4h')" class="mt-4 mb-4 mr-0 ml-0">
                            <h2 :style="'color: ' + colore4h">| 4 HOURS |</h2>
                        </v-btn>
                    </v-flex>
                    <v-flex xs2 sm6 md3>
                        <v-btn text @click="ChangeDate('1')" class="mt-4 mb-4 mr-0 ml-0">
                            <h2 :style="'color: ' + colore1d">| 1 DAY |</h2>
                        </v-btn>
                    </v-flex>
                    <v-flex xs2 sm6 md3>
                        <v-btn text @click="ChangeDate('3')" class="mt-4 mb-4 mr-0 ml-0">
                            <h2 :style="'color: ' + colore3d">| 3 DAYS |</h2>
                        </v-btn>
                    </v-flex>
                    <v-flex xs2 sm6 md3>
                        <v-btn text @click="ChangeDate('7')" class="mt-4 mb-4 mr-0 ml-0">
                            <h2 :style="'color: ' + colore1w">| 1 WEEK |</h2>
                        </v-btn>
                    </v-flex>
                    <v-flex xs2 sm6 md3>
                        <v-btn text @click="ChangeDate('14')" class="mt-4 mb-4 mr-0 ml-0">
                            <h2 :style="'color: ' + colore2w">| 2 WEEKS |</h2>
                        </v-btn>
                    </v-flex>
                </v-layout>
            </v-card-text>
        </v-card>
        <hr class="mt-4" style="border-top: solid 1px; color: #BFCCE4" />
        <div class="mt-4">
            <h2 inline-block v-if="!okconsumption">{{ sensor_name }}</h2>
            <v-layout v-if="okconsumption">
                <v-flex xs12 sm6 md6>
                    <h2 inline-block>{{ sensor_name }}</h2>
                </v-flex>
                <v-flex xs12 sm6 md6>
                    <h2 class="mr-8">Weekly consumption: {{ weeklyCons }}</h2>
                </v-flex>
            </v-layout>
        </div>
        <hr class="mt-4" style="border-top: solid 1px; color: #BFCCE4" />
        <v-card class="mt-4" height="100%" flat v-show="visualizza_dati && !scelta">
            <v-card-text>

                <v-layout class="mt-4 mr-4 mb-4 ml-4">
                    <v-flex xs12 sm12 md6>
                        <v-card height="100%" class="mt-4 mr-4 mb-4 ml-4" flat>
                            <h2 class="mb-2">DATA MANAGER</h2>
                            <v-layout>
                                <v-flex sm12 md12>
                                    <h3 class="text-center mt-8">Sensor name:</h3>
                                    <p class="text-center">{{ sensor_name }}</p>
                                    <img class="mt-2 mr-4 align-center" style="align:center" :src="sensor_img"
                                        width="50%" />
                                </v-flex>
                                <v-flex sm12 md12>
                                    <v-card-text class="text-center">

                                        <h3 class="mt-4">Building:</h3> {{ building_name }}
                                        <h3 class="mt-8">Floor: </h3> {{ floor_name }}

                                        <h3 class="mt-8">Last refilling: </h3> {{ last_refill }}
                                    </v-card-text>
                                </v-flex>
                            </v-layout>
                        </v-card>
                    </v-flex>
                    <div style="border-left: 1px solid #BFCCE4; height: 350px;" class="mt-4"></div>
                    <v-flex xs12 sm12 md6>
                        <v-card height="100%" class="mt-4 mr-4 mb-4 ml-4" flat>
                            <h2 class="mb-2">DISPENSER LEVEL</h2>
                            <v-card-text class="justify-center">
                                <v-layout>
                                    <v-flex xs12 sm12 md12 class="justify-center mt-8">
                                        <!-- <apexchartComponent type="radialBar" height="350" :options="PunctualchartOptions" :series="series_punctual" :key="radialKey"></apexchartComponent> -->
                                        <apexchartComponent type="bar" height="50" :options="PunctualchartOptions"
                                            :series="series_punctual"></apexchartComponent>
                                    </v-flex>
                                </v-layout>
                                <v-layout>
                                    <v-flex xs12 sm12 md12 class="text-left mt-2">
                                        <div class="mt-0" style="display: inline-block;">
                                            <h3 class="text-left" style="display: inline-block;">Time: </h3>
                                            <h4 style=" font-weight: normal;">{{ GiornoSettimana }} - {{ DataSensore }}
                                                - {{
            OraSensore }} </h4>
                                        </div>
                                        <h3 class="mt-8">Level status: </h3> <v-icon style="display: inline-block;"
                                            :color="colore_stato">mdi-circle</v-icon>
                                    </v-flex>
                                </v-layout>

                            </v-card-text>
                        </v-card>
                    </v-flex>
                </v-layout>
                <hr class="mt-4" style="border-top: solid 1px; color: #BFCCE4" />
                <v-layout class="mt-4 mr-4 mb-4 ml-4">
                    <v-flex xs12 sm12 md12>
                        <v-card class="mt-4 mr-4 mb-4 ml-4" height="100%" flat>
                            <v-card-title>
                                <v-layout>
                                    <!-- <v-flex xs12 sm12 md3>
                                <v-select
                                v-model="displayed_range"
                                :items="to_display"
                                label="Select displayed range"
                                >
                                </v-select>
                            </v-flex> -->
                                    <v-flex xs12 sm12 md12>

                                    </v-flex>
                                </v-layout>
                            </v-card-title>
                            <h2>DISPENSER LEVEL </h2>
                            <v-card-text>
                                <apexchartComponent type="line" height="350" :options="chartOptions_bin"
                                    :series="series_dispenser" />
                                <v-btn v-if='$store.state.company == "1"' text color="#213A8F"
                                    @click="DownloadCsv()"><v-icon>mdi-download</v-icon>Download
                                    chart data</v-btn>
                                <v-btn text color="#213A8F" v-if='$store.state.company == "1"'
                                    @click="DownloadCompleteCsv()"><v-icon>mdi-download</v-icon>Download all sensor
                                    data</v-btn>
                            </v-card-text>

                            <v-card-actions>

                            </v-card-actions>
                        </v-card>
                    </v-flex>

                </v-layout>
                <hr class="mt-4" style="border-top: solid 1px; color: #BFCCE4" />
                <v-layout class="mt-4 mr-4 mb-4 ml-4">
                    <v-flex xs12 sm12 md12>
                        <v-card class="mt-4 mr-4 mb-4 ml-4" height="100%" flat>
                            <h2>FULL - EMPTY DISPENSER</h2>
                            <v-card-text>
                                <apexchartComponent type="bar" height="350" :options="chartOptions_stacked"
                                    :series="series_stacked" :key="keyStacked" />
                            </v-card-text>
                        </v-card>
                    </v-flex>
                </v-layout>
                <hr class="mt-4" style="border-top: solid 1px; color: #BFCCE4" />
                <v-layout class="mt-4 mr-4 mb-4 ml-4">
                    <v-flex xs12 sm12 md12>
                        <v-card class="mt-4 mr-4 mb-4 ml-4" height="100%" flat>
                            <h2>EMPTY HOUR(S) </h2>
                            <v-card-text>
                                <apexchartComponent max-width="20%" type="bar" height="350" :options="chartOptions_bar"
                                    :series="series_bar" :key="keyBar" />
                            </v-card-text>
                        </v-card>
                    </v-flex>
                </v-layout>
                <v-layout class="mt-4 mr-4 mb-4 ml-4">
                    <v-flex xs12 sm12 md12>
                        <v-card class="mt-4 mr-4 mb-4 ml-4" height="100%" flat>
                            <h2 v-if="okconsumption">CONSUMPTIONS </h2>
                            <v-card-text v-if="okconsumption">
                                <apexchartComponent max-width="20%" type="bar" height="350" :options="chartOptions_cons"
                                    :series="series_consumptions" :key="keyCons" />
                            </v-card-text>
                        </v-card>
                    </v-flex>
                </v-layout>

            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
import axios from "axios";
export default {
    name: 'SmartBinView',
    data() {
        return {
            client: "",
            clients: [],
            buildingActive: true,
            areaActive: true,
            building : "",
            buildings: [],
            floor: "",
            floors: [],
            area: "",
            areas: [],
            series_consumptions: [],
            keyCons: 0,
            okconsumption: false,
            weeklyCons: 150,
            maxDate: "",
            minDate: "",
            datiPerCsv: [],
            scelta: true,
            visualizza_dati: false,
            sensor_img: "",
            sensorActive: true,
            floorActive: true,
            i: 0,
            b: 0,
            s: 0,
            colore4h: "#DAE0EC",
            colore1d: "#DAE0EC",
            colore3d: "#DAE0EC",
            colore1w: "#DAE0EC",
            colore2w: "#DAE0EC",
            colore_stato: "",
            overlay: false,
            building_name: "",
            floor_name: "",
            level_status: 0,
            last_refill: "2023-02-01",
            GiornoSettimana: '',
            OraSensore: '',
            DataSensore: '',
            keyBar: 0,
            keyStacked: 0,
            radialKey: 0,
            sensor_name: "",
            colors: "#18ff00",
            series_punctual: [],
            PunctualchartOptions: {
                colors: [
                    "#009CAB",
                    "#dae0ec",
                ],
                states: {
                    hover: {
                        filter: {
                            type: 'none',
                            value: 0,
                        }
                    }
                },
                chart: {
                    type: 'bar',
                    stacked: true,
                    stackType: "100%",
                    offsetY: -20,
                    sparkline: {
                        enabled: true
                    }
                },
                dataLabels: {
                    enabled: true,
                    formatter: function (val, { seriesIndex, dataPointIndex, w }) {
                        dataPointIndex;
                        if (w.config.series[seriesIndex].name != 'Empty')
                            return val + "%"
                        else
                            return ''
                    },
                },
                xaxis: {
                    max: 100,
                    min: 0,
                },
                yaxis: {
                    labels: {
                        style: {
                            colors: '#BFCCE4',
                        },
                    },
                    axisTicks: {
                        colors: '#BFCCE4',
                    },
                },
                plotOptions: {
                    states: {
                        hover: {
                            filter: {
                                type: 'none',
                                value: 0,
                            }
                        }
                    },
                    bar: {
                        borderRadius: 4,
                        columnWidth: '10%',
                        horizontal: true,
                        startAngle: -90,
                        endAngle: 90,
                        track: {
                            background: "#e7e7e7",
                            strokeWidth: '97%',
                            margin: 5, // margin is in pixels
                            dropShadow: {
                                enabled: true,
                                top: 2,
                                left: 0,
                                color: '#999',
                                opacity: 1,
                                blur: 2
                            }
                        },
                    },
                },
                fill: {
                    type: 'gradient',
                    gradient: {
                        shade: 'dark',
                        type: "horizontal",
                        shadeIntensity: 0.5,
                        inverseColors: true,
                        gradientToColors: ['#213A8F'],
                        gradientFromColors: ['#009CAB'],
                        opacityFrom: 1,
                        opacityTo: 1,
                        stops: [0, 100]
                    },
                },
            },

            start_complete: '',
            nextDay_complete: '',
            handPaperRolls: 0,
            foldedTowels: 0,
            users: 0,
            averageUsers: 0,
            menu: false,
            date: [],
            floor_items: [],
            floorValue: "",
            buildingValue: [],
            lista_bin: [],
            lista_stacked: [],
            valueBin: [],
            valueStacked: [],
            valueBar: [],
            valueVuoto: [],
            valueRotoli: [],
            valueDispenser: [],
            dialogBar: false,
            dialogBin: false,
            dialogStacked: false,
            dialogVuoto: false,
            dialogRotoli: false,
            dialogDispenser: false,
            building_items: [],
            series_bin: [],
            series_stacked: [],
            series_bar: [],
            series_dispenser: [],
            series_rotoli: [],
            series_vuoto: [],
            sensor_unique: '',
            chartOptions_bin: {

                colors: ['#BFCCE4'],
                dropShadow: {
                    enabled: false,
                    enabledOnSeries: undefined,
                    top: 0,
                    left: 0,
                    blur: 3,
                    color: '#000',
                    opacity: 0.35
                },
                chart: {
                    toolbar: {
                        show: false,
                    },
                    type: 'line',
                    height: 700,
                },
                responsive: [{
                    breakpoint: 480,
                    options: {
                        show: false,
                        legend: {
                            position: 'bottom',
                            offsetX: -10,
                            offsetY: 0
                        }
                    }
                }],
                yaxis: {
                    min: 0,
                    max: 100,

                    labels: {
                        offset: 10,
                        style: {
                            colors: '#BFCCE4',
                        },
                        formatter: function (value) {
                            return Number(value).toFixed(0) + "%";
                        },
                    }
                },
                xaxis: {
                    colors: '#213A8F',
                    categories: [],
                    labels: {
                        style: {
                            colors: '#213A8F',
                            fontSize: '8px',
                            fontFamily: 'Helvetica, Arial, sans-serif',
                            fontWeight: 400,
                            cssClass: 'apexcharts-xaxis-label',
                        },
                    },
                },
                fill: {
                    opacity: 1
                },
                legend: {
                    show: false,
                    position: 'right',
                    offsetX: 0,
                    offsetY: 50
                },
            },

            chartOptions_stacked: {
                states: {
                    hover: {
                        filter: {
                            type: 'none',
                            value: 0,
                        }
                    }
                },
                plotOptions: {
                    bar: {
                        columnWidth: '70%',
                        
                    },
                },
                //colors:['#BFCCE4','#008ffb'],
                chart: {

                    toolbar: {
                        show: false,
                    },
                    type: 'bar',
                    height: 350,
                    stacked: true,
                    stackType: '100%'
                },
                yaxis: {
                    labels: {
                        offset: 10,
                        style: {
                            colors: '#BFCCE4',
                        },
                        formatter: function (value) {
                            return Number(value).toFixed(0) + "%";
                        },
                    }
                },
                xaxis: {
                    colors: '#213A8F',
                    categories: [],
                    labels: {
                        style: {
                            colors: '#213A8F',
                            fontSize: '8px',
                            fontFamily: 'Helvetica, Arial, sans-serif',
                            fontWeight: 400,
                            cssClass: 'apexcharts-xaxis-label',
                        },
                    },
                },
                fill: {
                    colors: ['#BFCCE4', '#213A8F'],
                    
                },
                legend: {
                    show: false,
                },
                title: {
                    text: "",
                    rotate: -90,
                    offsetX: 0,
                    offsetY: 0,
                    style: {
                        color: undefined,
                        fontSize: '12px',
                        fontFamily: 'Helvetica, Arial, sans-serif',
                        fontWeight: 600,
                        cssClass: 'apexcharts-yaxis-title',
                    },
                }
            },
            crosshairs: {
            },
            chartOptions_bar: {
                states: {
                    hover: {
                        filter: {
                            type: 'none',
                            value: 0,
                        }
                    }
                },
                chart: {
                    toolbar: {
                        show: false,
                    },
                    height: 350,
                    type: 'line',
                    zoom: {
                        enabled: false
                    }
                },
                dataLabels: {
                    enabled: false
                },
                fill: {
                    colors: ['#BFCCE4', '#DAE0EC']
                },
                stroke: {
                    curve: 'straight'
                },
                xaxis: {
                    colors: '#213A8F',
                    categories: [],
                    labels: {
                        style: {
                            colors: '#213A8F',
                            fontSize: '8px',
                            fontFamily: 'Helvetica, Arial, sans-serif',
                            fontWeight: 400,
                            cssClass: 'apexcharts-xaxis-label',
                        },
                    },
                },
                yaxis: {
                    labels: {
                        style: {
                            colors: '#BFCCE4',
                        },
                    },
                },
                colors: '#BFCCE4',
            },
            chartOptions_cons: {
                states: {
                    hover: {
                        filter: {
                            type: 'none',
                            value: 0,
                        }
                    }
                },
                chart: {
                    toolbar: {
                        show: false,
                    },
                    height: 350,
                    type: 'line',
                    zoom: {
                        enabled: false
                    }
                },
                dataLabels: {
                    enabled: false
                },
                fill: {
                    colors: ['#BFCCE4', '#DAE0EC']
                },
                stroke: {
                    curve: 'straight'
                },
                xaxis: {
                    colors: '#213A8F',
                    categories: [],
                    labels: {
                        style: {
                            colors: '#213A8F',
                            fontSize: '8px',
                            fontFamily: 'Helvetica, Arial, sans-serif',
                            fontWeight: 400,
                            cssClass: 'apexcharts-xaxis-label',
                        },
                    },
                },
                yaxis: {
                    labels: {
                        style: {
                            colors: '#BFCCE4',
                        },
                    },
                },
                colors: ['#213A8F'], // Cambia il colore delle linee in blu
            },

            sensor_id: "",
            sensor_items: [],
            sensorsValue: "",
            okActive: true,
            minValue: 0,
            maxValue: 0
        }

    },

    watch: {
        client(value){
            if (value != undefined &&
                value != null &&
                value != "" &&
                value != 0
            ) {
                this.buildingActive = false;
                this.buildings.length = 0;
                this.buildingValue = '';
                this.buildings = [];
                this.floorActive = true;
                this.floors.length = 0;
                this.floor = '';
                this.floors = [];
                this.floors.length = 0;
                this.areaActive = true;
                this.areas.length = 0;
                this.area = '';
                this.areas = [];
                this.areas.length = 0;
                this.sensorActive = true;
                this.sensor_items.length = 0;
                this.sensorsValue = '';
                this.sensor_items = [];
                this.sensor_items.length = 0;

                axios.post(this.$store.state.URL_GET_AVAILABLE_BUILDINGS, {
                    companyId: value
                })
                    .then(res => {
                        res.data.forEach(el => {
                            var element = { text: el.BuildingName, value: el.id }
                            this.buildings.push(element);

                        });
                    })
            }
            else {
                this.buildingActive = false;
                this.buildings.length = 0;
                this.buildingValue = '';
                this.buildings = [];
                this.floorActive = true;
                this.floors.length = 0;
                this.floor = '';
                this.floors = [];
                this.floors.length = 0;
                this.areaActive = true;
                this.areas.length = 0;
                this.area = '';
                this.areas = [];
                this.areas.length = 0;
                this.sensorActive = true;
                this.sensor_items.length = 0;
                this.sensorsValue = '';
                this.sensor_items = [];
                this.sensor_items.length = 0;
            }
            
        },
        building(value) {
            if (value != undefined &&
                value != null &&
                value != "" &&
                value != 0
            ) {
                
                
                this.floorActive = true;
                this.floors.length = 0;
                this.floor = '';
                this.floors = [];
                this.floors.length = 0;
                this.areaActive = true;
                this.areas.length = 0;
                this.area = '';
                this.areas = [];
                this.areas.length = 0;
                this.sensorActive = true;
                this.sensor_items.length = 0;
                this.sensorsValue = '';
                this.sensor_items = [];
                this.sensor_items.length = 0;

                this.floorActive = false;
                axios.post(this.$store.state.URL_GET_AVAILABLE_FLOORS, {
                    buildingId: value
                })
                    .then(res => {
                        res.data.forEach(el => {
                            var element = { text: el.FloorName, value: el.id }
                            this.floors.push(element);
                        });

                    })
            }
            else {
                this.floorActive = false;
                this.floors.length = 0;
                this.floor = '';
                this.floors = [];
                this.floors.length = 0;
                this.areaActive = true;
                this.areas.length = 0;
                this.area = '';
                this.areas = [];
                this.areas.length = 0;
                this.sensorActive = true;
                this.sensor_items.length = 0;
                this.sensorsValue = '';
                this.sensor_items = [];
                this.sensor_items.length = 0;
            }
        },
        floor(value) {
            if (value != undefined &&
                value != null &&
                value != "" &&
                value != 0
            ) {
                
                this.areaActive = true;
                this.areas.length = 0;
                this.area = '';
                this.areas = [];
                this.areas.length = 0;
                this.sensorActive = true;
                this.sensor_items.length = 0;
                this.sensorsValue = '';
                this.sensor_items = [];
                this.sensor_items.length = 0;
                this.areaActive = false;
                axios.post(this.$store.state.URL_GET_AVAILABLE_AREAS, {
                    floorId: value
                })
                    .then(res => {
                        res.data.forEach(el => {
                            var element = { text: el.AreaName, value: el.id }
                            this.areas.push(element);
                        });

                    })
            }
            else{
                
                this.areaActive = false;
                this.areas.length = 0;
                this.area = '';
                this.areas = [];
                this.areas.length = 0;
                this.sensorActive = true;
                this.sensor_items.length = 0;
                this.sensorsValue = '';
                this.sensor_items = [];
                this.sensor_items.length = 0;
            }

        },
        area(value) {
            if (value != undefined &&
                value != null &&
                value != "" &&
                value != 0
            ) {
                this.sensor_items.length = 0;
                this.sensor_items = [];
                this.sensorsValue = "";
                this.sensorActive = false;
                // var el = { text: "...", value: "" };
                // this.filterSensors.push(el);
                axios.post(this.$store.state.URL_GET_SENSORS_COMPANY_BUILDING_FLOOR_AREA, {
                    companyId: this.client,
                    buildingId: this.building,
                    floorId: this.floor,
                    areaId: value,
                    type: "Dispenser"
                })
                    .then(res => {
                        res.data.forEach(el => {
                            var element = { text: el.DisplayName, value: el }
                            this.sensor_items.push(element);
                        });

                    })
            }
            else {
                this.sensor_items.length = 0;
                this.sensor_items = [];
                this.sensorsValue = "";
            }
        },
        buildingValue(value) {
            this.visualizza_dati = false;
            this.floor_items.length = 0;
            this.sensor_items.length = 0;
            value.floors.forEach(el => {
                this.floor_items.push({ text: el.FloorName, value: el })
            })
            this.building_name = value.BuildingName;
            this.floorActive = false;
            this.sensorActive = true;
            this.sensor_name = "";
            this.sensor_img = "";
            this.floor_name = "";
            this.f++;
            this.s++;
        },
        floorValue(value) {
            this.visualizza_dati = false;
            this.sensor_items.length = 0;
            value.sensors.forEach(el => {
                if (el.DispenserType == "Dispenser")
                    this.sensor_items.push({ text: el.DisplayName, value: el })
            })

            this.floor_name = value.FloorName;
            this.sensor_name = "";
            this.sensor_img = "";
            this.sensorActive = false;
            this.sensorsValue = '';
            this.s++;
        },
        sensorsValue(value) {
            //this.visualizza_dati = true;
            this.sensor_name = value.DisplayName;
            this.sensor_img = value.DispenserImage;
            this.sensor_unique = value.DispenserUnique;
            this.sensor_id = value.DispenserId;
            this.minValue = value.Min_Value;
            this.maxValue = value.Max_Value;
            this.okActive = false;
            this.colore4h = "#DAE0EC"
            this.colore1d = "#213A8F"
            this.colore3d = "#DAE0EC"
            this.colore1w = "#DAE0EC"
            this.colore2w = "#DAE0EC"
            this.date = [];
            this.LoadData();
        }
    },
    created() {
        if (this.$store.state.company == null || this.$store.state.company == undefined || this.$store.state.company == '') {
            let token = localStorage.getItem("token");
            if (token != null && token != undefined && token != '') {
                axios.defaults.headers.common["Authorization"] = token;
                axios.post('https://2ahfbwerq7qttajotsaeim3g6i0dvrte.lambda-url.eu-central-1.on.aws/',
                    {

                    })
                    .then(res => {
                        if (res.data.result == "ok") {
                            this.$store.state.company = res.data.company;
                            localStorage.setItem('token', res.data.token);
                            localStorage.setItem('company', res.data.company)
                            this.$store.state.menu = res.data.menu;
                            console.log(this.$store.state.menu)
                            this.$store.state.avatar = res.data.avatar;
                            this.$store.state.displayName = res.data.displayName;
                            axios.defaults.headers.common["Authorization"] = res.data.token;
                            axios.post(this.$store.state.URL_COMPANY_DATA, {
                                "Company": this.$store.state.company
                            })
                                .then(() => {
                                    this.overlay = true;
                                    this.DownloadCompanyDatas();
                                    // this.colore1d = "#213A8F"
                                    // this.$store.state.building = res.data
                                })
                                .catch(() => {
                                    this.overlay = false;
                                    this.$router.push({ path: "/" });
                                })
                        }
                    })
            }
        }
        else {
            this.overlay = true;
            this.DownloadCompanyDatas();
        }
    },
    mounted() {
        //this.overlay = true;


        // this.GetDataStacked(7);
        // // this.buildingValue = this.building_items[0];
        // // this.b ++;
        // this.date.push(new Date().toISOString().toString().substr(0,10));
        // this.GetData(new Date().toISOString().toString().substr(0,10),new Date(),0)
        // this.date.push(new Date().toISOString().toString().substr(0,10));
        // this.GetSensorData(this.subtractDays(-4, new Date()).toISOString().toString().substr(0,10), this.subtractDays(1, new Date()).toISOString().toString().substr(0,10));
    },
    methods: {
        SetMinDate() {
            this.minDate = this.maxDate;
        },
        GetDataByDate() {
            this.overlay = true;
            this.GetSensorData(this.subtractDays(0, new Date(this.date[0])).toISOString().toString().substr(0, 10), this.subtractDays(1, new Date(this.date[1])).toISOString().toString().substr(0, 10));
        },
        DownloadCsv() {
            var dati = this.datiPerCsv;
            var csvCont = "Sensor;DateTime;Fill level\n";
            dati.map(e => {
                csvCont += this.sensor_name + ";" + e.received_at + ";" + e.tof_distance + "%\n"
            })
            let csvContent = "data:text/csv;charset=utf-8," + csvCont;
            var encodedUri = encodeURI(csvContent);
            const link = document.createElement("a");
            link.setAttribute("href", encodedUri);
            link.setAttribute("download", "export.csv");
            link.click();
        },
        DownloadCompleteCsv() {
            var dati = [];
            axios.post("https://wk3st5cmujbxpjrdvcvce74y3i0wyxdt.lambda-url.eu-central-1.on.aws/",
                {
                    "sensor_id": this.sensor_unique,
                    "from": this.start_complete,
                    "to": this.nextDay_complete
                }
            )
                .then(res => {
                    dati = res.data;
                    var csvCont = "Sensor;DateTime;Fill level\n";
                    dati.map(e => {
                        console.log(e);
                        csvCont += this.sensor_name + ";" + e.device_data.received_at + ";" + (100 - (((Number(e.device_data.tof_distance) - this.minValue) * 100) / this.maxValue).toFixed(0)).toString() + "%\n"
                    })
                    let csvContent = "data:text/csv;charset=utf-8," + csvCont;
                    var encodedUri = encodeURI(csvContent);
                    const link = document.createElement("a");
                    link.setAttribute("href", encodedUri);
                    link.setAttribute("download", "data_export.csv");
                    link.click();
                });


        },
        DownloadCompanyDatas() {
            axios.post(this.$store.state.URL_GET_AVAILABLE_CLIENT)
                .then(res => {
                    res.data.forEach(el => {
                        var element = { text: el.CompanyName, value: el.id }
                        this.clients.push(element);

                    })
                })
                // .catch(() => {
                //     this.overlay = false;
                //     this.$router.push({ path: "/" });
                // })
            axios.post(this.$store.state.URL_COMPANY_DATA, {
                "Company": this.$store.state.company
            })
                .then(res => {
                    this.$store.state.building = res.data
                    this.building_items.length = 0;
                    this.floor_items.length = 0;

                    this.$store.state.building.buildings.forEach(el => {
                        var found = false;
                        var c = 0;
                        el.floors.forEach(e => {
                            e.sensors.forEach(s => {
                                found = Object.values(s).includes("Dispenser")
                                if (found)
                                    c++;
                                console.log(e, found)
                            })

                        })

                        if (c > 0) {
                            if (this.$store.state.company == '1')
                                this.building_items.push({ text: el.BuildingName.replace('Zwetko Headquarter', 'Airport'), value: el });
                            else
                                this.building_items.push({ text: el.BuildingName, value: el });
                        }
                    })
                })
                .catch(() => {
                    //Redirect alla pagina di errore
                    //this.snackbar = true;
                })
        },
        LoadData() {
            this.overlay = true;
            this.scelta = false;
            this.GetDataStacked(1);
            this.date.push(new Date().toISOString().toString().substr(0, 10));
            this.maxDate = new Date().toISOString().toString().substr(0, 10);
            //this.GetData(new Date().toISOString().toString().substr(0,10),new Date(),0)
            this.date.push(new Date().toISOString().toString().substr(0, 10));
            this.GetSensorData(this.subtractDays(-1, new Date()).toISOString().toString().substr(0, 10), this.subtractDays(1, new Date()).toISOString().toString().substr(0, 10));
            this.GetConsumptionData()
        },
        GetConsumptionData() {
            axios.post("https://s52tg2543bn575qjkgixshs5j40gocla.lambda-url.eu-central-1.on.aws/",
                {
                    "sensore": this.sensor_unique,
                }
            )
                .then(res => {
                    if (res.data == null) {
                        this.okconsumption = false
                    }
                    else {
                        this.weeklyCons = res.data;
                        this.okconsumption = true
                        this.GetConsumptionsChart();
                    }
                })
                .catch(() => {
                    this.weeklyCons = 0;
                })
        },
        GetConsumptionsChart() {
            axios.post("https://tjzks6siiq5bqwdgbmldcu7fgu0sbwil.lambda-url.eu-central-1.on.aws/",
                {
                    "id_sensor": this.sensor_unique,
                }
            )
                .then(res => {
                    this.series_consumptions.length = 0;
                    this.chartOptions_cons.xaxis.categories.length = 0;
                    var el = [];
                    res.data.sort((a, b) => (a.Data > b.Data) ? 1 : -1)
                    res.data.forEach(element => {
                        el.push(element.ConsumiGiornalieri);
                        this.chartOptions_cons.xaxis.categories.push(element.Data);
                    });
                    this.series_consumptions = [{ data: el, name: this.sensor_name }]; // Modifica qui
                    //this.chartOptions_cons.xaxis.categories.push(el.received_at.substr(0, 10) + "-" + el.received_at.substr(11, 5));
                    console.log(res.data);
                });
        },
        ChangeDate(period) {
            this.overlay = true;
            switch (period) {
                case "4h":
                    this.GetSensorData(this.subtractHours(4, new Date()).toISOString().toString().substr(0, 19), new Date().toISOString().toString().substr(0, 19), 1);
                    this.GetDataStacked(1);
                    this.chartOptions_stacked.plotOptions.bar.columnWidth = "15%"
                    this.colore4h = "#213A8F"
                    this.colore1d = "#DAE0EC"
                    this.colore3d = "#DAE0EC"
                    this.colore1w = "#DAE0EC"
                    this.colore2w = "#DAE0EC"
                    break;
                case "1":
                    this.GetSensorData(this.subtractDays(0, new Date()).toISOString().toString().substr(0, 10), this.subtractDays(1, new Date()).toISOString().toString().substr(0, 10), 1);
                    this.GetDataStacked(1);
                    this.chartOptions_stacked.plotOptions.bar.columnWidth = "15%"
                    this.colore4h = "#DAE0EC"
                    this.colore1d = "#213A8F"
                    this.colore3d = "#DAE0EC"
                    this.colore1w = "#DAE0EC"
                    this.colore2w = "#DAE0EC"
                    break;
                case "3":
                    this.GetDataStacked(3);
                    this.GetSensorData(this.subtractDays(-2, new Date()).toISOString().toString().substr(0, 10), this.subtractDays(1, new Date()).toISOString().toString().substr(0, 10));
                    this.chartOptions_stacked.plotOptions.bar.columnWidth = "40%"
                    this.colore3d = "#213A8F";
                    this.colore4h = "#DAE0EC"
                    this.colore1d = "#DAE0EC"
                    this.colore1w = "#DAE0EC"
                    this.colore2w = "#DAE0EC"
                    break;
                case "7":
                    this.GetDataStacked(7);
                    this.GetSensorData(this.subtractDays(-6, new Date()).toISOString().toString().substr(0, 10), this.subtractDays(1, new Date()).toISOString().toString().substr(0, 10));
                    this.chartOptions_stacked.plotOptions.bar.columnWidth = "70%"
                    this.colore1w = "#213A8F";
                    this.colore3d = "#DAE0EC";
                    this.colore4h = "#DAE0EC"
                    this.colore1d = "#DAE0EC"
                    this.colore2w = "#DAE0EC"
                    break;
                case "14":
                    this.GetDataStacked(14);
                    this.GetSensorData(this.subtractDays(-13, new Date()).toISOString().toString().substr(0, 10), this.subtractDays(1, new Date()).toISOString().toString().substr(0, 10), 2);
                    this.chartOptions_stacked.plotOptions.bar.columnWidth = "70%"
                    this.colore2w = "#213A8F";
                    this.colore1w = "#DAE0EC";
                    this.colore3d = "#DAE0EC";
                    this.colore4h = "#DAE0EC"
                    this.colore1d = "#DAE0EC"
                    break;
            }
        },
        subtractHours(numOfHours, date = new Date()) {
            date.setHours(date.getHours() - numOfHours + 1);
            return date;
        },
        subtractDays(numOfDays, date = new Date()) {
            date.setDate(date.getDate() + numOfDays);

            return date;
        },
        GetLastRefill(sensore) {
            axios.post(this.$store.state.URL_LASTMANAGED, {
                sensor: sensore
            }).
                then(res => {
                    if (res.data.length > 0)
                        this.last_refill = res.data[0].Timestamp_Gestione;
                    else
                        this.last_refill = "Not managed yet";
                    console.log("Last refill: ", res.data);
                })
        },
        GetSensorData(start, nextDay, time = 0, skip = 0) {
            console.log(time)
            start = start.length == 10 ? start + " 00:00:00" : start.replace("T", " ");
            nextDay = nextDay.length == 10 ? nextDay + " 00:00:00" : nextDay.replace("T", " ");
            this.start_complete = start;
            this.nextDay_complete = nextDay;
            this.GetLastRefill(this.sensor_unique);
            axios.post("https://pgfq23vx6z7bkueil3xxj6kyty0vvpkt.lambda-url.eu-central-1.on.aws/",
                {
                    "sensor_id": this.sensor_unique,
                    "from": start,
                    "to": nextDay
                }
            )
                .then(res => {
                    console.log(skip);
                    this.series_dispenser.length = 0;
                    this.sensor_datas = res.data.Items;
                    this.loading = false;
                    this.series_bin.length = 0;
                    this.chartOptions_bin.xaxis.categories.length = 0;
                    var data_ = [];
                    var dati = [];

                    for (var i = res.data.length - 1; i >= 0; i--) {
                        if (res.data[i].device_data.received_at != undefined)
                            dati.push(res.data[i].device_data);
                    }
                    var j = 0;
                    i = 0;
                    var precedente = 0;
                    var val_ok = false;
                    dati = dati.sort((a, b) => (a.received_at > b.received_at) ? 1 : -1)
                    console.log(dati);
                    //Recupero i valori per i punctual data
                    const weekday = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
                    const d = new Date();
                    let day = weekday[d.getDay()];
                    this.GiornoSettimana = day;
                    this.OraSensore = dati[dati.length - 1].received_at.substr(11, 5);
                    this.DataSensore = dati[dati.length - 1].received_at.substr(0, 10);
                    var valore = 100 - (((Number(dati[dati.length - 1].tof_distance - this.minValue)) * 100) / (this.maxValue - this.minValue)).toFixed(0);
                    // this.series_stacked.length = 0;
                    if (valore < 0)
                        valore = 0;
                    if (valore > 100)
                        valore = 100;
                    if (valore > 40)
                        this.colore_stato = "green";
                    if (valore >= 15 && valore <= 40)
                        this.colore_stato = "#ffac27";
                    if (valore < 15)
                        this.colore_stato = "red";
                    this.radialKey += 1;
                    //this.series_punctual = [{name: this.sensor_name, data: [valore]}, {name: 'Empty', data: [100-valore]}];
                    //   var div = 1;
                    //   if(time == 1 )
                    //     div = 1;
                    //   else if (time == 0)
                    //     div = 15;
                    //   else if (time == 2)
                    //     div = 25;
                    //   dati = dati.filter((e, index)=>{
                    //     return index%div == 0;
                    //   })
                    this.datiPerCsv = [];
                    const datiLength = dati.length;
                    dati.map((el, index) => {
                        var valore = 100 - (((Number(el.tof_distance) - this.minValue) * 100) / (this.maxValue - this.minValue)).toFixed(0);
                        if (valore < 0)
                            valore = 0;
                        if (valore > 100)
                            valore = 100;
                        if (valore >= 0 && valore <= 100) {
                            if (index === datiLength - 1) {
                                val_ok = true;
                            }
                            else if (i != 0 && Math.abs(precedente - valore) < 5) {
                                val_ok = false;

                            }
                            else {
                                val_ok = true;
                                precedente = valore;
                            }
                            if (val_ok) {
                                data_.push(valore);
                                this.datiPerCsv.push({ tof_distance: valore, received_at: el.received_at })
                            }
                            else {
                                data_.push(precedente);
                                this.datiPerCsv.push({ tof_distance: precedente, received_at: el.received_at })
                            }
                            i++

                        }
                        else {
                            data_.push(100);
                            this.datiPerCsv.push({ tof_distance: 100, received_at: el.received_at })
                        }
                        this.chartOptions_bin.xaxis.categories.push(el.received_at.substr(0, 10) + "-" + el.received_at.substr(11, 5));
                        console.log(j, time)
                    });
                    this.series_dispenser.push({ data: data_, name: this.sensor_name });
                    this.series_punctual = [{ name: this.sensor_name, data: [data_[data_.length - 1]] }, { name: 'Full', data: [100 - data_[data_.length - 1]] }];
                    this.overlay = false;

                })
                .catch(() => {
                    this.loading = false;
                    this.overlay = false;
                })
            this.visualizza_dati = true;
        },
        // GetData(value){
        //     var nextDay = new Date((Number(value.replaceAll("-", ""))+1).toString().substr(0,4) + "-" + 
        //                         (Number(value.replaceAll("-", ""))+1).toString().substr(4,2) + "-" +
        //                         (Number(value.replaceAll("-", ""))+1).toString().substr(6,2)).toISOString().toString().substr(0,10);
        //     var today = new Date((Number(value.replaceAll("-", ""))).toString().substr(0,4) + "-" + 
        //                         (Number(value.replaceAll("-", ""))).toString().substr(4,2) + "-" +
        //                         (Number(value.replaceAll("-", ""))).toString().substr(6,2)).toISOString().toString().substr(0,10);
        //     axios.post("https://api.akenza.io/v3/devices/" + this.sensor_id + "/query",
        //     {
        //     "limit": 1,
        //     "timestamp": {
        //         "gte": today,
        //         "lte": nextDay
        //     },
        //     "topic": "default",
        //     "skip": 0
        //     }
        //     ).
        //     then(res=> {
        //         const weekday = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];
        //         const d = new Date();
        //         let day = weekday[d.getDay()];
        //         this.GiornoSettimana = day;
        //         this.OraSensore = res.data[0].timestamp.substr(11,5);
        //         this.DataSensore = res.data[0].timestamp.substr(0,10);
        //         var valore = 100-(((Number(res.data[0].data.tof_distance) -this.minValue)* 100)/this.maxValue).toFixed(0);
        //         // this.series_stacked.length = 0;
        //         if (valore < 0)
        //             valore = 0;
        //         if (valore > 100)
        //             valore = 100;
        //         if(valore > 60)
        //             this.colore_stato = "#00ff4e";
        //         if (valore >= 20 && valore <= 60)
        //             this.colore_stato = "#ffac27";
        //         if(valore < 20)
        //             this.colore_stato = "#ff2727";
        //         this.radialKey += 1;
        //         this.series_punctual = [{data: [valore]}];
        //     })
        //     .catch(err=>{
        //         console.log(err);
        //     })
        // },
        GetDataStacked(days) {
            axios.post(this.$store.state.URL_STACKED, {
                sensor: this.sensor_id,
                ndays: days
            }).
                then(res => {
                    this.chartOptions_stacked.xaxis.categories.length = 0;
                    this.series_stacked.length = 0;
                    this.series_bar.length = 0;
                    var elementi_vuoto = [];
                    var elementi_pieno = [];
                    var ore_vuoto = [];
                    var categories = [];
                    res.data.forEach(el => {
                        elementi_vuoto.push(el.Vuoto);
                        elementi_pieno.push(el.Pieno);
                        categories.push(el.DataRegistrazione);
                        ore_vuoto.push(el.OreVuoto);

                    })
                    this.series_bar = [{ name: "Empty hours", data: ore_vuoto }];
                    this.series_stacked = [{
                        name: "Full", data: elementi_pieno
                    }, {
                        name: "Empty", data: elementi_vuoto
                    }]
                    console.log(this.series_stacked)
                    this.chartOptions_stacked.xaxis.categories = categories;
                    //this.chartOptions_stacked.colors = ['#BFCCE4','#008ffb']
                    this.chartOptions_bar.xaxis.categories = categories;
                    this.keyStacked += 1
                    this.keyBar += 1
                })

                .catch(err => {
                    console.log(err);
                })
        }

    },

}
</script>
<style scoped>
.miochart.apexcharts-bar-area:hover {
    fill: #213A8F !important;
}

.apexcharts-line-area:hover {
    fill: #213A8F !important;
}

.v-select__selection,
.v-select__selection--comma,
.v-select.v-text-field input {
    color: #213A8F !important;
}

.v-icon.v-incon {
    color: #213A8F !important;
}

#changeMyColor .v-text-field--outlined fieldset {
    border: #BFCCE4 1px solid !important;
}

.mt-4 mb-4 mr-4 ml-4 v-btn v-btn--text theme--light v-size--default {
    border: #213A8F 1px solid !important;
}
</style>