import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    URL_LOGIN : "https://2csz564xlw5gax4hodngpuujv40gwirk.lambda-url.eu-central-1.on.aws/",
    URL_ALERTS : "https://kdffdmslpvjlz3t77wd366xzlu0pfuue.lambda-url.eu-central-1.on.aws/",
    URL_UPDATE_ALERTS: "https://2x4aaat3p6hxvcfvahgzl674je0vfiuq.lambda-url.eu-central-1.on.aws/",
    URL_COMPANY_DATA: "https://6dezmxvnzzxhb6inus3ckiqvva0lguld.lambda-url.eu-central-1.on.aws/",
    URL_STACKED: "https://sncysfniqo5hhkcjgypjjxiw3a0duksb.lambda-url.eu-central-1.on.aws/",
    URLPOSTALERTS: "https://h4irfvzk5akc2xuxt2odai7m240fawqd.lambda-url.eu-central-1.on.aws/",
    URLGETALERTSENSORS: 'https://lyknfl56oryuqtaph3pjt3hcdq0eszmn.lambda-url.eu-central-1.on.aws/',
    URLGETALERTSMANAGEMENT: 'https://37jruropg3pmmbnxsfp5jqkdky0ocevv.lambda-url.eu-central-1.on.aws/',
    URL_SENSORSMANAGEMENT: 'https://rzhqcqmhh5tnrb66s5megiu7jq0zvsbl.lambda-url.eu-central-1.on.aws/',
    URL_BUILDINGSMANAGEMENT: 'https://s5emufe3tflsf2q3zqlcqd6e5y0joerf.lambda-url.eu-central-1.on.aws/',
    URL_GETSCHEDULEDATA: 'https://xr4syykbr2fuqfflncmwh4s2ze0eqqhz.lambda-url.eu-central-1.on.aws/',
    URL_LASTMANAGED: 'https://4juvarsn4h6ysjjca3jtnugjka0triow.lambda-url.eu-central-1.on.aws/',
    URL_DISPENSERTYPE: 'https://hzib3d6cotzpjpjnfjkopw7tlm0rzngn.lambda-url.eu-central-1.on.aws/',
    URL_GET_DISPENSER_BRAND: 'https://5enspwtf4r47h7bkwdcp2umvwm0glfyh.lambda-url.eu-central-1.on.aws/',
    URL_GET_AVAILABLE_CLIENT: 'https://rcwnqeq4niyc37sjqwfqlfoxke0qjcdc.lambda-url.eu-central-1.on.aws/',
    URL_GET_AVAILABLE_BUILDINGS: 'https://bidcojftipqnzw5gqxqg3ymz440lzenu.lambda-url.eu-central-1.on.aws/',
    URL_GET_AVAILABLE_FLOORS: 'https://igqv52lxkb7ztlfth3nkpqgeca0psbsg.lambda-url.eu-central-1.on.aws/',
    URL_GET_AVAILABLE_AREAS: 'https://oe3mrobb5kdm5vf6ox3ratpzdy0jhprt.lambda-url.eu-central-1.on.aws/',
    URL_GET_DISPENSER_MODELS: 'https://qhvu7avgtkvf5org7y7sca6aim0srzen.lambda-url.eu-central-1.on.aws/',
    URL_GET_CONSUMABLE_BRANDS: 'https://6anakvepzdgb7rlrmd4yqhpmta0pwfrb.lambda-url.eu-central-1.on.aws/',
    URL_GET_CONSUMABLE_MODELS: 'https://3kmrpey73g32zvnlqyqrhpc6mu0xjdrk.lambda-url.eu-central-1.on.aws/',
    URL_GET_DISPENSER_CODES: 'https://jhjhbzt6xpw7ts5jjlyjeri5hy0ymmzx.lambda-url.eu-central-1.on.aws/',
    URL_GET_SENSORS_COMPANY_BUILDING_FLOOR_AREA: 'https://uxcdev2hsw2uuiw3vybfbykj6u0dhnzq.lambda-url.eu-central-1.on.aws/',
    URL_CREATE_NEW_SENSOR: 'https://6ync47hnwmwvs542b2z6hqrpq40avdtr.lambda-url.eu-central-1.on.aws/',
    URL_DELETE_SENSOR: 'https://bucxooeug55vkgq2gwvuarmbc40ifdba.lambda-url.eu-central-1.on.aws/',
    URL_SAVE_NEW_ALERT_CONFIGURATION : 'https://tyvs4hbvqntpuacz5367usy72q0ytwrk.lambda-url.eu-central-1.on.aws/',
    URL_CREATE_BUILDING: 'https://4klwdtrxjsqdkitb6hpwxyfaqe0ybigz.lambda-url.eu-central-1.on.aws/',
    URL_CREATE_FLOOR: 'https://fpiiaaqjyhbooqd6rqmztwljk40exfpu.lambda-url.eu-central-1.on.aws/',
    URL_CREATE_AREA: 'https://zxg22iwh7hkrevvgqjgi5k6imm0wzlqf.lambda-url.eu-central-1.on.aws/',
    building: [],
    company : '',
    menu : [],
    avatar: '',
    displayName: '',
    sensorFiltered: '',
    floorFiltered: '',
    buildingFiltered: '',
    isFilterActive: ''
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
