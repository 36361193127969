<template>
    <v-container>
        <v-overlay v-model="overlay" color="white" opacity="1" persistent>
        <v-container style="text-align: center">
            
        <v-progress-circular
            :size="50"
            color="#234a98"
            indeterminate
        ></v-progress-circular>
        <p class="pt-2" style="font-size: 14px; color: #234a98">
            Loading...
        </p>
        </v-container>
        </v-overlay>
        <v-layout style="justify-content: center;" align="center">
            <v-flex xs12 sm12>
                <img class="mt-16" src="../assets/YT_logo_png.png" style="height: 145px;" />
            </v-flex>
        </v-layout>
        <v-dialog persistent max-width="600" v-model="forgotPassword">
            <v-card>
                <v-card-title>Reset Password</v-card-title>
                <v-card-text>
                    <p>Please enter the email used during the registration</p>
                    <v-text-field label="Email" v-model="email" outlined color="#213A8F"
                     />
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text color="#213A8F" @click="forgotPassword = false">Cancel</v-btn>
                    <v-btn text color="#213A8F" @click="CheckAndSendResetEmail">Confirm</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-layout style="justify-content: center;" class="text-xs-center">
            <v-flex xs12 sm12 md12>
                <div style="display: flex; align-items: center; justify-content: center; " align="center" class="my-5">  
                    <v-card style="max-width: 800px; v-align:center;" >
                        <v-card-text >
                            <v-form>
                                <v-text-field color="#213A8F" class="text_youtissue" v-model="username" filled label="username" prepend-inner-icon="mdi-account" outlined/>
                                <v-text-field color="#213A8F" v-model="password" filled label="password" prepend-inner-icon="mdi-lock"
                                            outlined :append-icon="value ? 'mdi-eye' : 'mdi-eye-off'" 
                                            @click:append=" () => (value = !value)" :type="value ? 'password' : 'text'" @keypress="CheckInvio()"/>
                                <v-btn @click="Login" class="login-button">{{ $t('key001') }}</v-btn>
                            </v-form>
                        </v-card-text>
                    </v-card>
                </div>
            </v-flex>
        </v-layout>
        <v-layout style="justify-content: center;" class="text-xs-center">
            <v-btn text @click="forgotPassword = true;">Forgot Password?</v-btn>
        </v-layout>
        <v-snackbar
        v-model="snackbar"
        :timeout="timeout"
        color="red accent-2"
        >

        {{ $t('key006') }}

            <template v-slot:action="{ attrs }">
                <v-btn
                color="blue"
                text
                v-bind="attrs"
                @click="snackbar = false"
                >
                {{ $t('key007') }}
                </v-btn>
                <v-snackbar
                v-model="snackbarReset"
                color="green"
                ></v-snackbar>
            </template>
        </v-snackbar>
        <v-snackbar
        v-model="snackbarReset"
        color="green"
        >Reset email successfully sent.</v-snackbar>
    </v-container>
</template>

<script>
import axios from "axios";
export default {
    data() {
        return {
            forgotPassword: false,
            email: '',
            overlay: false,
            username : "",
            password : "",
            value: true,
            snackbar: false,
            snackbarReset: false,
            timeout: 5000
        }
    },
    methods: {
        CheckAndSendResetEmail(){
            this.snackbarReset = true;
        },
        CheckInvio(evt){
            evt = evt ? evt : window.event;
            var charCode = evt.which ? evt.which : evt.keyCode;
            if (charCode === 13) {
                this.Login();
      }
        },
        Login(){
            this.overlay = true;
        axios.post(this.$store.state.URL_LOGIN,{
            "user": this.username,
            "password": this.password
        })
        .then(res=>{
            if(res.data.result == "ok"){
                var homepage = null;
                console.log(res.data.homepage)
                if(res.data.homepage.length != 0)
                    homepage = res.data.homepage[0].route;
                console.log(homepage)
                this.$store.state.company = res.data.company;
                localStorage.setItem('token', res.data.token);
                localStorage.setItem('company', res.data.company)
                this.$store.state.menu = res.data.menu;
                this.$store.state.avatar = res.data.avatar;
                this.$store.state.displayName = res.data.displayName;
                axios.defaults.headers.common["Authorization"] = res.data.token;
                axios.post(this.$store.state.URL_COMPANY_DATA,{
                    "Company" : this.$store.state.company
                })
                .then(res=>{
                    this.overlay = false;
                    this.$store.state.building = res.data
                    console.log(this.$store.state.building)
                    if(homepage != null)
                        this.$router.push({ path: homepage }); 
                    else{
                        if(this.$store.state.company == '1')
                            this.$router.push({ path: `/dashboards` }); 
                        else
                            this.$router.push({ path: `/welcome` });
                    }
                })
                .catch(()=>{
                    this.overlay = false;
                    this.snackbar = true;
                })
            }
            else{
                this.overlay = false;
                this.snackbar = true;
        }
        });
        },
    }
}
</script>